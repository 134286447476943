<template>
  <div class="o-my-account-wishlist-header">
    <div v-if="isDesktop" class="o-my-account-wishlist-header__title">
      {{ $t('My lists') }}
    </div>
    <div class="o-my-account-wishlist-header__actions">
      <div
        class="action-create-new-group"
        data-transaction-name="Account - Create New Wishlist Group"
        @click="$emit('createNewWishlist')"
      >
        <span class="action-create-new-group__icon">
          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle opacity=".1" cx="12" cy="12" r="12" fill="#EB6747" />
            <path d="M12 7v5m0 0H7m5 0v5m0-5h5" stroke="#EB6747" />
          </svg>
        </span>
        <span class="action-create-new-group__title">
          {{ $t('Create new list') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import DeviceType from 'theme/mixins/DeviceType'

export default {
  name: 'MyAccountWishlistHeader',
  mixins: [DeviceType]
}
</script>

<style lang="scss" scoped>
@import "~theme/css/px2rem";

.o-my-account-wishlist-header {
  padding: 0;
  margin-bottom: var(--spacer-30);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__title {
    font-family: var(--font-family-secondary);
    font-size: var(--font-size-24);
    font-weight: var(--font-medium);
    line-height: normal;
  }

  &__actions {
    .action-create-new-group {
      cursor: pointer;
      display: flex;
      align-items: center;

      &__icon {
        width: 24px;
        height: 24px;
      }

      &__title {
        margin-left: px2rem(10);
        color: var(--orange);
        font-size: var(--font-size-14);
        font-weight: var(--font-normal);
      }
    }
  }
}
</style>
