<template>
  <section>
    <div class="sf-review">
      <!-- @slot Review product. Slot content will replace default <div> tag (bind 'product' string). -->
      <slot name="product" v-bind="{ product }">
        <div class="sf-review__product">
          <div class="image-column">
            <!-- @slot Review image. Slot content can be replaced by custom image. -->
            <slot name="image">
              <SfImage
                v-if="productLink === ''"
                class="sf-review__image"
                :src="productImage || '/assets/placeholder.svg'"
                alt="product"
              />
              <SfLink
                v-else
                :link="localizedRoute('/' + productLink)"
                class="main-column__text"
              >
                <SfImage
                  class="sf-review__image"
                  :src="productImage || '/assets/placeholder.svg'"
                  alt="product"
                />
              </SfLink>
            </slot>
          </div>
          <div class="main-column">
            <div class="">
              <!-- @slot Review information. Slot content will replace default <div> tag and its inner <div> tags (bind 'rating' boolean|number, 'maxRating' number, 'date' string). -->
              <slot name="info" v-bind="{ rating, maxRating, date }">
                <div class="sf-review__info">
                  <div class="sf-review__rating">
                    <SfORating :max="maxRating" :score="rating" />
                  </div>
                </div>
              </slot>
            </div>
            <div
              v-if="productLink === ''"
              class="main-column__text"
            >
              {{ product }}
            </div>
            <SfLink
              v-else
              :link="localizedRoute('/' + productLink)"
              class="main-column__text"
            >
              {{ product }}
            </SfLink>
          </div>
          <div class="date-column">
            {{ getDate(date) }}
          </div>
        </div>
      </slot>
      <!-- @slot Review message. Slot content will replace default <div> tag and its inner <span> and <a> tags (bind 'finalMessage' computed property, 'buttonText' computed property). -->
      <slot name="message" v-bind="{ finalMessage, buttonText }">
        <div v-if="message">
          <div class="sf-review__message">
            {{ finalMessage }}
          </div>
          <SfButton
            v-if="showButton"
            class="sf-button--text sf-review__read-more"
            data-transaction-name="Review - Toggle Message"
            @click="toggleMessage"
          >
            {{ buttonText }}
          </SfButton>
        </div>
      </slot>
    </div>
    <!-- @slot Review answer message. Slot content will replace default <div> tag and its inner <span> and <a> tags (bind 'answer' computed property). -->
    <slot name="answer" v-bind="{ answer }">
      <div
        v-if="answer"
        v-for="item in answer"
        :key="item.id"
        class="sf-review__answer"
      >
        <div class="figure-column">
          <div />
        </div>
        <div class="content-column">
          <div
            v-if="item.type === 'admin'"
            class="content-column__image"
          >
            <svg
              width="70"
              height="16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7.1.5a10 10 0 0 0-2.2 4l-1.6-.9A9 9 0 0 0 1.1 3 10.6 10.6 0 0 0 1.5 7 6.4 6.4 0 0 1 7 8.7a6.4 6.4 0 0 1 5.7-1.6l.4-1.6V3a9 9 0 0 0-3.9 1.6A8.5 8.5 0 0 0 7.1.5Z" fill="#E84E0F" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M1.6 8a6 6 0 0 0 1 3c.6.8 1.4 1.5 2.3 2 .2-1.4.8-2.7 1.7-3.7a5.7 5.7 0 0 0-5-1.4ZM5.5 14.9l.4.2.7-1.7h.5c3 0 5.4-2.4 5.6-5.5l-1.2-.1c-1.4 0-2.8.6-3.9 1.5-1 1-1.7 2.4-2 4a9.6 9.6 0 0 1 4.6-4h.2A12.3 12.3 0 0 0 5.5 15Z" fill="#AAC91D" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M45.7 9.2c1.3-.5 2.4-1.4 2.4-3 0-2.2-1.5-3.3-3.6-3.3h-7c.3 0 .5.7.5 1.6v7c0 .9 1 1.6 1.7 1.6h1.6V13c-.3 0-.5-.3-.5-.9V9.3h2l.3.3 2.2 3s.4.5 1.5.5h2.5c-.3 0-.7-.5-1-.8l-2.6-3Zm-1.9-1.9h-3V4.8h2.9c1.3 0 1.6.6 1.6 1.3 0 .8-.5 1.2-1.5 1.2Z" fill="#E84E0F" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M55.6 3c.1 0 .4.6.4 1.5v4.8c0 1.2-1 1.7-2.2 1.7-1.2 0-2.2-.5-2.2-1.7V4.5c0-.9.3-1.5.4-1.5v-.1h-3.9c.4 0 .7.6.7 1.6v4.3c0 2.4 1.2 4.5 5 4.5s5-2 5-4.5V4.5c0-1 .3-1.5.7-1.5v-.1h-4ZM23.7 12.3l4-8.9c0-.2.2-.4.4-.4v-.1h-3.6s.3.4.2.7L24 5l-1 2.2-1.5 3.4L20 7.2 19 5l-.6-1.4.2-.6v-.1h-3.6c.2 0 .3.3.4.5l4 9c0 .3 0 .6-.3.6H24c-.2 0-.3-.3-.2-.7ZM62.3 5.9c0-1 2-1 2.5-1 1.4 0 2.7.3 3.3 1.1l1.1-2.7h-.6c-.5 0-2.3-.5-4.4-.5-3.5 0-4.7 2-4.7 3.4.1 3.9 7.2 2.1 7.2 4 0 .9-1.4 1-2 1a9.6 9.6 0 0 1-4.1-1 2 2 0 0 1-.4-.4l-.7 2.6h.7c.1 0 2.6.8 4.7.8 3.3 0 4.7-1.5 4.7-3.4-.2-4-7.2-2.3-7.3-4ZM37.3 12.3l-3.7-8.5c-.2-.5-.1-.8.1-.8v-.1H29c.2 0 .3.4.1.9l-3.7 8.5c-.2.5-.5.7-.7.7h3.2l.9-2h5.1l.9 2H38c-.2 0-.5-.2-.7-.7Zm-6-3.6h-1.5l1.5-3.4L33 8.7h-1.5Z" fill="#E84E0F" />
            </svg>
          </div>
          <p
            v-else
            class="content-column__name"
          >
            {{ item.nickname }}
          </p>
          <div class="content-column__text">
            {{ item.content }}
          </div>
        </div>
      </div>
    </slot>
  </section>
</template>
<script>
import SfORating from 'theme/components/storefront-override/SFORating';
import { SfLink, SfImage, SfButton } from '@storefront-ui/vue';

export default {
  name: 'SfOReview',
  components: {
    SfORating,
    SfImage,
    SfButton,
    SfLink
  },
  props: {
    /**
     * Author of the review
     */
    product: {
      type: String,
      default: ''
    },
    /**
     * Link to the product
     */
    productLink: {
      type: String,
      default: '#'
    },
    /**
     * Image of the product
     */
    productImage: {
      type: String,
      default: ''
    },
    /**
     * Author of the review
     */
    author: {
      type: String,
      default: ''
    },
    /**
     * Date of the review
     */
    date: {
      type: String,
      default: ''
    },
    /**
     * Message from the reviewer
     */
    message: {
      type: String,
      default: ''
    },
    /**
     * Answer from the Varus
     */
    answer: {
      type: Array,
      default: () => []
    },
    /**
     * Rating from the reviewer
     */
    rating: {
      type: [Number, String, Boolean],
      default: false
    },
    /**
     * Max rating for the review
     */
    maxRating: {
      type: [Number, String],
      default: 5
    },
    /**
     * Char limit for the review
     */
    charLimit: {
      type: [Number, String],
      default: 250
    },
    /**
     * Read more text for the review
     */
    readMoreText: {
      type: String,
      default: 'Read more'
    },
    /**
     * Hide full text message for the review
     */
    hideFullText: {
      type: String,
      default: 'Read less'
    }
  },
  data () {
    return {
      isOpen: false
    };
  },
  computed: {
    showButton () {
      return this.message.length > this.charLimit;
    },
    buttonText () {
      let buttonText = this.readMoreText;
      if (this.isOpen) {
        buttonText = this.hideFullText;
      }
      return buttonText;
    },
    finalMessage () {
      return this.message.length > this.charLimit && !this.isOpen
        ? this.message.slice(0, this.charLimit) + '...'
        : this.message;
    }
  },
  methods: {
    toggleMessage () {
      this.isOpen = !this.isOpen;
    },
    getDate (date) {
      const formatDate = new Date(date)
      const day = formatDate.getDate()
      const month = formatDate.toLocaleString('en', { month: 'long' })
      const year = formatDate.getFullYear()
      return this.$t(`{day} ${month} {year}`, { day: day, year: year })
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/px2rem";

.sf-review {
  position: relative;
  display: var(--review-display, block);
  background-color: var(--light-gray);
  padding: var(--spacer-20);

  &__product {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    font-family: var(--font-family-secondary);
    font-weight: var(--font-normal);
    color: var(--dark-gray);

    .image-column {
      display: flex;
      align-items: center;
      justify-content: start;
      margin-right: var(--spacer-10);

      ::v-deep {
        .a-logo {
          min-width: var(--spacer-50);
        }
      }
    }

    .main-column {
      position: relative;
      flex: 1;
      font-size: var(--font-size-12);

      &__text {
        font-size: var(--font-size-12);
        margin-top: var(--spacer-2xs);
        color: var(--dark-gray);
      }
    }

    .date-column {
      position: absolute;
      top: var(--spacer-18);
      right: var(--spacer-20);
      flex-basis: px2rem(130);
      font-size: var(--font-size-13);
      text-align: right;
    }
  }

  &__image {
    width: 50px;
    height: 50px;
  }

  &__rating {
    display: var(--review-rating-display, inline-block);

    .sf-icon {
      --icon-size: var(--spacer-14)!important;
    }
  }

  &__info {
    margin: 0;
  }

  &__message {
    width: 100%;
    display: block;
    margin: var(--spacer-15) 0  var(--spacer-5);
    padding-top: var(--spacer-15);
    color: var(--black);
    vertical-align: middle;
    font-family: var(--font-family-secondary);
    font-weight: var(--font-normal);
    font-size: var(--font-size-14);
    border-top: 1px solid var(--line-gray);
  }

  &__answer {
    display: flex;
    flex-wrap: nowrap;

    .figure-column {
      height: var(--spacer-60);
      @media (min-width: $desktop-min){
        margin-left: var(--spacer-20);
      }
      @media (max-width: $mobile-max){
        margin-left: var(--spacer-20);
      }
      @media (min-width: $tablet-min) and (max-width: $tablet-max){
        margin-left: var(--spacer-40);
      }
      margin-right: var(--spacer-5);
      width: var(--spacer-15);
      display: flex;
      flex-direction: column;
    }

    .figure-column:first-child {
      border-left: 1px solid var(--orange);
      border-bottom: 1px solid var(--orange);
      height: var(--spacer-30);
    }

    .content-column {
      margin-top: var(--spacer-22);
      --image-width: 70px;
      --image-height: auto;
      line-height: normal;
      font-family: var(--font-family-secondary);
      font-weight: var(--font-normal);
      font-size: var(--font-size-14);
      color: var(--black);

      &__name {
        margin: 0 0 var(--spacer-5);
        font-size: var(--font-size-16);
        font-weight: var(--font-medium);
      }
    }
  }

  .sf-button--text {
    --button-color: var(--orange);
    --button-text-decoration: none;
  }

  @include for-desktop {
    --review-message-display: inline-block;
  }

  ::v-deep {
    .sf-icon {
      margin: 0 var(--spacer-2);
    }
  }

  &__read-more {
    margin: 0;
    font-size: var(--font-size-14);
    font-weight: var(--font-normal);
  }

}
</style>
