<template>
  <div class="o-my-account-notifications">
    <div
      v-if="isDesktop"
      class="notifications-head"
    >
      <div class="notifications-head__title">
        {{ $t('Manage notifications') }}
      </div>
    </div>
    <div class="notifications-content">
      <div class="types-row">
        <div class="col-title">
          <div class="col-title__title">
            {{ $t('mailingTypes') }}
          </div>
        </div>
        <div class="col-content">
          <div
            v-if="isMobile"
            class="col-title__title"
          >
            {{ $t('mailingTypes') }}
          </div>
          <div
            v-for="(item, index) in subscriptions"
            :key="item.type + '_' + index"
            class="content-item"
          >
            <ACheckboxToggle
              v-model="item.is_subscribed"
              :name="item.type"
              :title="item.title"
              @input="saveChanges('subscriptions', item.type, $event)"
            />
            <div class="content-item__subtitle">
              {{ item.subtitle }}
            </div>
          </div>
        </div>
      </div>
      <div class="space-row">
        <div class="space-row__col-one" />
        <div class="space-row__col-two" />
      </div>

      <div class="channels-row">
        <div class="col-title">
          <div class="col-title__title">
            {{ $t('connectionChannels') }}
          </div>
        </div>
        <div class="col-content">
          <div
            v-if="isMobile"
            class="col-title__title"
          >
            {{ $t('connectionChannels') }}
          </div>
          <div
            v-for="(item, index) in communications"
            :key="item.type + '_' + index"
            class="content-item"
          >
            <ACheckboxToggle
              v-model="item.is_active"
              :name="item.type"
              :title="item.title"
              @input="saveChanges('communications', item.type, $event)"
            />
            <div class="content-item__subtitle">
              {{ item.subtitle }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="dataLoading" class="data-loader__wrap">
        <SfLoader class="data-loader"/>
      </div>
    </div>
  </div>
</template>

<script>
import DeviceType from 'theme/mixins/DeviceType';
import ACheckboxToggle from 'theme/components/atoms/a-checkbox-toggle';
import SfLoader from 'theme/components/storefront-override/SfLoader'
import { Logger } from '@vue-storefront/core/lib/logger'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'OMyAccountNotifications',
  components: { ACheckboxToggle, SfLoader },
  mixins: [DeviceType],
  computed: {
    ...mapState({
      userCommunications: state => state['user-communication'].communications,
      userSubscriptions: state => state['user-communication'].subscriptions,
      dataLoading: state => state['user-communication'].communicationsLoading
    }),
    subscriptions () {
      return this.userSubscriptions?.map(e => {
        return {
          ...e,
          title: this.getChannelsData(e).title,
          subtitle: this.getChannelsData(e).subtitle,
          position: this.getChannelsData(e).position
        }
      }).sort((a, b) => {
        return a.position - b.position
      })
    },
    communications () {
      return this.userCommunications?.map(e => {
        return {
          ...e,
          title: this.getChannelsData(e).title,
          position: this.getChannelsData(e).position
        }
      }).sort((a, b) => {
        return a.position - b.position
      })
    }
  },
  methods: {
    ...mapActions({
      spawnNotification: 'notification/spawnNotification',
      loadCommunications: 'user-communication/loadCommunications',
      saveCommunications: 'user-communication/saveCommunications',
      saveSubscriptions: 'user-communication/saveSubscriptions'
    }),
    loadData () {
      if (!this.dataLoading) {
        this.loadCommunications({ skipLoader: false })
      }
    },
    async saveChanges (mode, type, value) {
      if (!mode || !type) return

      try {
        let response

        if (mode === 'communications') {
          const communication = { type, is_active: value }
          response = await this.saveCommunications({ communication })
        }

        if (mode === 'subscriptions') {
          const subscription = { type, is_subscribed: value }
          response = await this.saveSubscriptions({ subscription })
        }

        if (response.result?.status === 'success') {
          this.onSuccess(this.$t('Account data has successfully been updated'));
        } else {
          this.onFailure(this.$t(response?.result?.message));
        }
      } catch (err) {
        Logger.error(err, 'saveCommunications')()
        this.onFailure('Unexpected error when save user communications');
      } finally {
        this.loadCommunications({ skipLoader: false })
      }
    },
    getChannelsData (item) {
      switch (item.type) {
        // communications
        case 'EMAIL': return { title: this.$t('connectionChannels email title'), position: 1 }
        case 'VIBER_SMS': return { title: this.$t('connectionChannels viber_sms title'), position: 2 }
        case 'WEBPUSH': return { title: this.$t('connectionChannels web title'), position: 3 }
        case 'MOBILEPUSH': return { title: this.$t('connectionChannels mobile title'), position: 4 }

        // subscriptions
        case 'PROMOTIONS': return {
          title: this.$t('mailingTypes info title'),
          subtitle: this.$t('mailingTypes info subtitle'),
          position: 1
        }
        case 'NEWS': return {
          title: this.$t('mailingTypes news title'),
          subtitle: '',
          position: 2
        }
        case 'RECIPES': return {
          title: this.$t('mailingTypes recipes title'),
          subtitle: '',
          position: 3
        }
        case 'PERSONAL_PROMOTIONS': return {
          title: this.$t('mailingTypes personal suggestions title'),
          subtitle: '',
          position: 4
        }

        default: return { title: '', subtitle: '', position: 1 }
      }
    },
    onSuccess (message) {
      this.spawnNotification({
        type: 'success',
        message: message,
        action1: { label: this.$t('OK') }
      })
    },
    onFailure (message) {
      this.spawnNotification({
        type: 'danger',
        message: this.$t(message),
        action1: { label: this.$t('OK') }
      })
    }
  },
  mounted () {
    this.loadData()
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/breakpoints";

.notifications-head {
  color: var(--black);
  padding: 7px var(--spacer-25);

  @include for-desktop {
    padding-bottom: var(--spacer-30);
  }

  &__title {
    font-family: var(--font-family-secondary);
    font-size: var(--font-size-24);
    font-weight: var(--font-medium);
    line-height: normal;
  }
}

.notifications-content {
  color: var(--black);
  background-color: var(--white);
  box-sizing: border-box;
  min-height: 500px;
  position: relative;
}

.data-loader__wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  opacity: .8;
}

.data-loader {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.types-row {
  .col-title__title,.col-content {
    padding-top: var(--spacer-20);

    @include for-tablet {
      padding-top: var(--spacer-30);
    }
    @include for-desktop {
      padding-top: var(--spacer-50);
    }
  }
}

.channels-row {
  .col-title__title,.col-content {
    padding-bottom: var(--spacer-20);

    @include for-tablet {
      padding-bottom: var(--spacer-30);
    }
    @include for-desktop {
      padding-bottom: var(--spacer-50);
    }
  }
}

.space-row {
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--spacer-20);
  margin-bottom: var(--spacer-20);
  border-bottom: 2px solid var(--light-gray);

  @include for-tablet {
    height: var(--spacer-40);
    margin: 0;
    border-bottom: none;
  }
  @include for-desktop {
    height: var(--spacer-60);
    margin: 0;
    border-bottom: none;
  }

  &__col-one {
    box-sizing: border-box;
    flex-basis: 26%;
    min-width: 177px;
    border-right: 1px solid var(--gray3);
  }

  &__col-two {
    box-sizing: border-box;
    flex: 1;
  }
}
.types-row,
.channels-row {
  display: flex;

  .col-title {
    box-sizing: border-box;
    flex-basis: 26%;
    min-width: 177px;
    display: none;

    @include for-tablet {
      display: block;
      padding: 0 var(--spacer-20);
      border-right: 1px solid var(--gray3);
    }
    @include for-desktop {
      display: block;
      padding: 0 var(--spacer-50);
      border-right: 1px solid var(--gray3);
    }

    &__title {
      font-family: var(--font-family-secondary);
      font-size: var(--font-base);
      font-weight: var(--font-medium);
      line-height: normal;
    }
  }

  .col-content {
    box-sizing: border-box;
    flex: 1;
    padding-left: var(--spacer-15);
    padding-right: var(--spacer-15);

    @include for-tablet {
      padding-left: var(--spacer-30);
      padding-right: var(--spacer-30);
    }
    @include for-desktop {
      padding-left: var(--spacer-50);
      padding-right: var(--spacer-50);
    }

    .content-item:first-child {
      margin: 0 0 var(--spacer-20) 0;
    }
    .content-item:last-child {
      margin: var(--spacer-20) 0 0 0;
    }
    .content-item:not(:first-child):not(:last-child) {
      margin: var(--spacer-20) 0;
    }
    .content-item__subtitle {
       margin: var(--spacer-5) 0 0 42px;
       color: var(--dark-gray);
       font-family: var(--font-family-secondary);
       font-size: var(--font-size-12);
       font-weight: var(--font-normal);
       line-height: var(--spacer-18);
    }
  }
}

::v-deep {
  .sf-checkbox__container {
    width: fit-content;
  }
  .sf-checkbox__label {
    color: var(--black);
    line-height: var(--spacer-20);
    margin-left: var(--spacer-10);
  }
}
</style>
