<template>
  <div class="m-orders-list">
    <div class="order-period">
      <SfOSelect
        class="order-period__select sf-select--filled"
        :label="selectTitle"
        :value="ordersPeriod.period"
        :selected="ordersPeriod.period"
        @change="onDateSelect"
        @click.native="onDateSelectClick"
        @dropdownToggle="onDateSelectToggle"
      >
        <SfSelectOption
          v-for="(item, i) in orderSelectPeriods"
          :key="i"
          :value="item.value"
        >
          {{ item.label }}
        </SfSelectOption>
        <template #icon>
          <span class="order-period__select-icon" :class="dateSelectIconClass" />
        </template>
      </SfOSelect>
      <m-date-picker
        class="data-picker-component"
        v-if="showDatePicker"
        :range="true"
        :confirm="true"
        @dateChange="onDatePickerChange"
      />
    </div>
    <template v-if="orders.length">
      <OAccountOrderListItem
        v-for="(order, i) in orders"
        :key="i"
        :order="order"
        @showOrder="showOrder"
      />
      <div class="after-items-space" />
      <div v-if="total > 1" class="orders-list__pagination-wrap">
        <div class="before-pagination-space" />
        <SfOPagination
          :current="currentPage"
          :total="total"
          :per-page="pageSize"
          :count-range-pages="countRangePages"
          @loadMoreFromPagEmit="loadMoreFromPagEmitFunction"
          @countRangePagesEmit="handlerUpdateOrdersHistory({})"
          page-param-name="orderPage"
          class="orders-list__pagination"
        />
      </div>
      <ALoadingSpinner
        key="spinner"
        class=""
        v-if="isShowLoader"
        :is-absolute-position="true"
      />
    </template>
    <div
      v-else
      class="m-orders-list-no-order"
    >
      <p class="m-orders-list-no-order__title">
        {{ $t('No orders found') }}
      </p>
      <p class="m-orders-list-no-order__text">
        {{ $t('Try to view the history of orders for another period') }}
      </p>
    </div>
  </div>
</template>

<script>
import DeviceType from 'theme/mixins/DeviceType';
import SfOPagination from 'theme/components/storefront-override/SfOPagination';
import SfOSelect from 'theme/components/storefront-override/SfOSelect'
import { mapActions, mapGetters } from 'vuex';
import { getFormattedDateTime, dateAdd, getFullDayDates } from 'theme/helpers/orders';
import MDatePicker from 'theme/components/molecules/m-date-picker';
import ALoadingSpinner from 'theme/components/atoms/a-loading-spinner'
import config from 'config'
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import OAccountOrderListItem from 'theme/components/organisms/o-account-order-list-item'

export default {
  name: 'MAccountOrdersList',
  components: {
    SfOPagination,
    SfOSelect,
    MDatePicker,
    ALoadingSpinner,
    OAccountOrderListItem
  },
  mixins: [DeviceType],
  data () {
    return {
      orderSelectPeriods: [
        { value: 'today', 'label': this.$t('Today') },
        { value: 'yesterday', 'label': this.$t('Yesterday') },
        { value: 'last7', 'label': this.$t('Last {number} days', { number: 7 }) },
        { value: 'last28', 'label': this.$t('Last {number} days', { number: 28 }) },
        { value: 'all', 'label': this.$t('All the time') },
        { value: 'currentYear', 'label': new Date().getFullYear() },
        { value: 'lastYear', 'label': new Date().getFullYear() - 1 },
        { value: 'dateRange', 'label': this.$t('Different date range') }
      ],
      ordersPeriod: {
        period: 'all',
        dateStart: null,
        dateEnd: null
      },
      currentPage: 1,
      showDatePicker: false,
      isShowBonusCredit: false,
      isShowLoader: false,
      isDateSelectOpen: false,
      countRangePages: [],
      bodyElement: document.querySelector('body')
    }
  },
  props: {
    orders: {
      type: Array,
      default: () => []
    },
    pageSize: {
      type: Number,
      default: config.ordersHistory.pageSize
    },
    ordersType: {
      type: String,
      default: 'all'
    },
    skipOrdersUpdate: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      totalCurrent: 'user/getOrdersHistoryTotalCurrent',
      ordersDateFrom: 'user/getOrdersHistoryDateFrom',
      ordersDateTo: 'user/getOrdersHistoryDateTo'
    }),
    selectedPeriod () {
      return {
        dateStart: this.ordersPeriod.dateStart,
        dateEnd: this.ordersPeriod.dateEnd
      }
    },
    total () {
      return Math.ceil(this.totalCurrent(this.ordersType) / this.pageSize)
    },
    selectTitle () {
      const dates = this.ordersPeriod.period === 'all'
        ? [this.ordersDateFrom, this.ordersDateTo]
        : [this.selectedPeriod.dateStart, this.selectedPeriod.dateEnd];

      const [start, end] = dates.map(date => getFormattedDateTime(date, false));

      const separator = start && end ? ' — ' : '';
      const titleStart = start ? `${start} ${this.yearSymbol}` : '';
      const titleEnd = end ? `${end} ${this.yearSymbol}` : '';

      return (titleStart + separator + titleEnd) || ''
    },
    dateSelectIconClass () {
      return {
        'order-period__select-icon--active': this.isDateSelectOpen
      }
    },
    yearSymbol () {
      const defaultLocale = currentStoreView().i18n.defaultLocale;
      return defaultLocale === 'uk-UA' ? 'р.' : defaultLocale === 'ru-RU' ? 'г.' : '';
    }
  },
  methods: {
    ...mapActions({
      loadOnlineOrdersHistory: 'user/loadOnlineOrdersHistory',
      loadOfflineOrdersHistory: 'user/loadOfflineOrdersHistory'
    }),
    setCurrentPage (newPage) {
      this.currentPage = newPage
    },
    showOrder (order) {
      this.$emit('showOrder', order)
    },
    async handlerUpdateOrdersHistory ({ addMore = false, status = this.ordersType }) {
      let currentPage = this.currentPage
      if (addMore) currentPage += 1
      this.isShowLoader = true

      try {
        if (status === 'all') {
          await this.loadOnlineOrdersHistory({})
          // await this.loadOfflineOrdersHistory({})
        } else if (status === 'online') {
          await this.loadOnlineOrdersHistory({
            addMore,
            fromDate: this.selectedPeriod.dateStart,
            toDate: this.selectedPeriod.dateEnd,
            pageSize: this.pageSize,
            currentPage
          })
        } else {
          // await this.loadOfflineOrdersHistory({
          //   addMore,
          //   fromDate: this.selectedPeriod.dateStart,
          //   toDate: this.selectedPeriod.dateEnd,
          //   pageSize: this.pageSize,
          //   currentPage
          // })
        }
      } finally {
        this.isShowLoader = false
      }
    },
    updateHistoryAfterOrderCancel () {
      this.handlerUpdateOrdersHistory({})
    },
    async loadMoreFromPagEmitFunction () {
      this.countRangePages.push(this.currentPage);
      await this.handlerUpdateOrdersHistory({ addMore: true })
      this.currentPage++;
      this.countRangePages.push(this.currentPage);
      await this.$router.push({ query: { ...this.$route.query, orderPage: this.currentPage }, params: { loadMore: true } });
    },
    setOrdersPeriod (period) {
      this.ordersPeriod = period
    },
    onDatePickerChange (date) {
      if (!date.includes(null)) {
        this.setOrdersPeriod({
          period: this.ordersPeriod.period,
          dateStart: getFullDayDates(date[0], 'start'),
          dateEnd: getFullDayDates(date[1], 'end')
        })
        this.handlerUpdateOrdersHistory({ status: this.ordersType })
      }
      this.showDatePicker = false
    },
    onDateSelect (period) {
      const selectedPeriod = this.getSelectedPeriod(period)
      this.setOrdersPeriod({ period, ...selectedPeriod })

      if (period === 'dateRange') {
        this.showDatePicker = true
      } else {
        this.handlerUpdateOrdersHistory({ status: this.ordersType })
      }
    },
    onDateSelectClick (e) {
      if (this.showDatePicker && e.target.id === 'sfSelect') this.showDatePicker = false
    },
    onDateSelectToggle (visible) {
      this.isDateSelectOpen = visible
    },
    getSelectedPeriod (value) {
      const dateCalculations = {
        all: [null, null],
        today: [getFullDayDates(new Date(), 'start'), getFullDayDates(new Date(), 'end')],
        yesterday: [getFullDayDates(dateAdd(new Date(), 'd', -1), 'start'), getFullDayDates(dateAdd(new Date(), 'd', -1), 'end')],
        last7: [getFullDayDates(dateAdd(new Date(), 'd', -7), 'start'), getFullDayDates(new Date(), 'end')],
        last28: [getFullDayDates(dateAdd(new Date(), 'd', -28), 'start'), getFullDayDates(new Date(), 'end')],
        currentYear: [getFullDayDates(new Date(new Date().getFullYear(), 0, 1), 'start'), getFullDayDates(new Date(), 'end')],
        lastYear: [getFullDayDates(new Date(new Date().getFullYear() - 1, 0, 1), 'start'), getFullDayDates(dateAdd(new Date(new Date().getFullYear(), 0, 1), 'd', -1), 'end')]
      }

      let [dateStart, dateEnd] = dateCalculations[value] || [null, null]
      return { dateStart, dateEnd }
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (to, from) {
        if (from === 'undefined') return

        if (to.query.orderPage) {
          this.setCurrentPage(parseInt(to.query.orderPage));
        } else {
          this.setCurrentPage(1);
        }
        this.handlerUpdateOrdersHistory({})
      }
    }
  },
  mounted () {
    this.currentPage = 1
    this.$router.push({ query: { orderPage: this.currentPage } })
    this.$bus.$on('order-after-canceled', this.updateHistoryAfterOrderCancel)
  },
  beforeDestroy () {
    this.$bus.$off('order-after-canceled', this.updateHistoryAfterOrderCancel)
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";
@import '~theme/css/fonts';

.m-orders-list {
  ::v-deep {
    .sf-loader__overlay {
      padding-top: px2rem(170);
      align-items: start;

      @media (max-width: $mobile-max) {
        margin: 0 -10px;
      }
    }
  }

  &-no-order {
    &__title {
      margin: var(--spacer-5) 0 var(--spacer-10);
      font-size: var(--font-size-24);
      font-weight: var(--font-medium);
      color: var(--dark-gray);

      @media (max-width: $mobile-max) {
        font-size: var(--font-size-16);
      }
    }

    &__text {
      margin: 0;
      font-size: var(--font-size-16);
      font-weight: var(--font-normal);
      color: var(--black);

      @media (max-width: $mobile-max) {
        font-size: var(--font-size-14);
      }
    }
  }
}

.after-items-space {
  background-color: var(--white);
  height: 50px;

  @media (max-width: $mobile-max) {
    height: 25px;
  }
}

.orders-list__pagination-wrap {
  background-color:  var(--light-gray);

  @media (max-width: $mobile-max) {
    padding-top: var(--spacer-40);
    padding-bottom: var(--spacer-5);
    position: relative;
    margin: 0 -10px;
  }

  .sf-link--primary {
    font-size: var(--font-sm);
    padding: var(--spacer-20) 0 0;

    @media (min-width: $tablet-min) {
      padding-top: 0;
      padding-left: var(--spacer-30);
    }
  }

  .before-pagination-space {
    background-color:  var(--gray-light);
    height: var(--spacer-25);
    margin: 0 -50px;

    @include for-tablet {
      margin: 0 -40px;
    }

    @media (max-width: $mobile-max) {
      display: none;
    }
  }

  .orders-list__pagination {
    position: relative;
    background-color:  var(--light-gray);
    align-items: center;
    justify-content: end;
    padding: var(--spacer-25);
    margin: 0 -50px;

    @include for-tablet {
      margin: 0 -40px;
    }

    @media (max-width: $mobile-max) {
      margin: 0;
      position: static;
      justify-content: center;
    }

    ::v-deep {
      .router-link-active,
      .sf-pagination__item {
        color: var(--orange);
        transition: color 0.3s ease-in-out;

        &:hover {
          color: var(--orange-hover);
        }
      }
      .sf-pagination__item--current {
        color: var(--black);
      }
      .sf-pagination__item:not(.sf-pagination__item--current):not(.sf-link) {
        color: var(--black);
      }
      .sf-pagination__item {
        &--next--disable,
        &--prev--disable {
          &:after {
            border-color: var(--dark-gray);
          }
        }
      }
    }
  }
}

.order-period {
  max-width: px2rem(610);
  margin-top: var(--spacer-20);
  border-bottom: 1px solid var(--gray3);

  @media (max-width: $mobile-max) {
    margin-top: var(--spacer-15);
  }

  .order-period__select {
    margin-bottom: var(--spacer-25);
    width: 355px;

    @media (max-width: $mobile-max) {
      width: 100%;
      margin-bottom: var(--spacer-15);
    }

    ::v-deep {
      .sf-select__selected {
        width: auto;
        color: black;
        font-size: var(--font-size-15);
      }

      .sf-select__dropdown {
        position: absolute;
        top: 58px;
        left: 0;
        right: 0;
        bottom: unset;
        z-index: 1;
        width: unset;
        box-shadow: 0 4px 5px rgba(0, 0, 0, 0.06);

        .sf-select__options {
          max-height: 400px!important;
        }

        .sf-select-option {
          color: black;
          font-size: var(--font-size-13);

          &:hover {
            color: var(--orange);
          }
        }
      }

      .sf-select__overlay {
        display: none;
      }

      .sf-button {
        justify-content: start;
      }

      .sf-select__cancel {
        padding: var(--spacer-sm);
        font-size: var(--font-size-13);
      }
    }

    &-icon {
      cursor: pointer;
      position: absolute;
      right: var(--spacer-16);
      top: 50%;
      transform: translateY(-50%);
      @include font-icon(var(--icon-shape));

      &:before {
        font-size: var(--font-size-11);
      }

      &--active {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
}

.data-picker-component {
  position: absolute;
  margin-top: -18px;
  box-shadow: 0 4px 11px rgba(var(--c-dark-base), 0.1);
}

</style>
