<template>
  <div class="o-my-account-reviews">
    <div class="reviews-head" v-if="isTablet || isDesktop">
      <div v-if="isDesktop" class="reviews-head__title">
        {{ $t('Reviews') }}
      </div>
    </div>
    <div class="reviews-wrap">
      <div v-if="!isViewStateList" class="reviews-empty">
        <div class="reviews-empty__outer-circle">
          <div class="reviews-empty__inner-circle">
            <svg width="34" height="33" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14 0h6a13 13 0 0 1 0 27v6c-8-4-20-9-20-20A13 13 0 0 1 14 0Z" fill="#333" />
            </svg>
          </div>
        </div>
        <div class="reviews-empty__text">
          <div class="reviews-empty__main-text">
            {{ $t('empty review text1') }}
          </div>
          <div class="reviews-empty__description">
            {{ $t('empty review text2') }}
          </div>
        </div>
      </div>
      <div v-else class="reviews-list-wrap">
        <div class="reviews-list">
          <SfTabs :open-tab="1">
            <SfTab :title="$t('All') + ' (' + reviewsAll.length + ')'">
              <div class="review-item">
                <MAccountReviewList :reviews="reviewsAll" />
              </div>
            </SfTab>
            <SfTab v-if="false" :title="$t('Products') + ' (' + reviewsProducts.length + ')'">
              <div class="review-item">
                <MAccountReviewList :reviews="reviewsProducts" />
              </div>
            </SfTab>
            <SfTab v-if="false" :title="$t('Recipes') + ' (' + reviewsRecipes.length + ')'">
              <div class="review-item">
                <MAccountReviewList :reviews="reviewsRecipes" />
              </div>
            </SfTab>
          </SfTabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DeviceType from 'theme/mixins/DeviceType';
import { SfTabs } from '@storefront-ui/vue';
import MAccountReviewList from 'theme/components/molecules/m-account-review-list';
import { mapGetters } from 'vuex';

export default {
  name: 'OMyAccountReviews',
  components: { MAccountReviewList, SfTabs },
  mixins: [DeviceType],
  data () {
    return {
      reviews: [
        {
          type: 'recipe',
          product: 'Рецепт. Пирог Valesto Балканский из вытяжного...',
          productLink: '/p/254812/chay-holodn-lisovi-yagodi-nestea-pet-0-5l',
          productImage: '/assets/placeholder.svg',
          author: 'First User',
          date: '7 марта 2021',
          message: 'Наши любимые пельмешки уже долгое время. Спасибо Varus 123 qwerty QWERTY за доступную цену! Наши любимые пельмешки уже долгое время. Спасибо Varus за доступную цену! Наши любимые пельмешки уже долгое время. Спасибо Varus за...Наши любимые пельмешки уже долгое время. Спасибо Varus за доступную цену! Наши любимые пельмешки уже долгое время. Спасибо Varus за доступную цену! Наши любимые пельмешки уже долгое время.',
          answer: 'Рады, что вам все понравилось.',
          rating: 3
        },
        {
          type: 'product',
          product: 'Продукт. Пирог Valesto Балканский из вытяжного...',
          productLink: '/osvitlyuvach-blond-classic-acme-color-1sht.html',
          author: 'Second User',
          date: '8 марта 2021',
          message: 'Наши любимые пельмешки уже долгое время. Спасибо Varus за доступную цену! Наши любимые пельмешки уже долгое время. Спасибо Varus за доступную цену! Наши любимые пельмешки уже долгое время.',
          answer: '',
          rating: 4
        },
        {
          type: 'recipe',
          product: 'Рецепт. Пирог Valesto Балканский из вытяжного...',
          productLink: '/p/177632/napiy-tarhun-rosinka-pet-2l',
          productImage: '/assets/placeholder.svg',
          author: 'Second User',
          date: '9 марта 2021',
          message: 'Наши любимые пельмешки уже долгое время. Спасибо Varus за доступную цену! Наши любимые пельмешки уже долгое время. Спасибо Varus за доступную цену! Наши любимые пельмешки уже долгое время.',
          answer: 'Рады, что вам все понравилось.',
          rating: 2
        },
        {
          type: 'product',
          product: 'Продукт. Пирог Valesto Балканский из вытяжного...',
          author: 'User',
          date: '10 марта 2021',
          message: 'Наши любимые пельмешки уже долгое время. Спасибо Varus за доступную цену! Наши любимые пельмешки уже долгое время. Спасибо Varus за доступную цену! Наши любимые пельмешки уже долгое время.',
          answer: '',
          rating: 4
        },
        {
          type: 'recipe',
          product: 'Рецепт. Пирог Valesto Балканский из вытяжного...',
          author: 'User',
          date: '11 марта 2021',
          message: 'Наши любимые пельмешки уже долгое время. Спасибо Varus за доступную цену! Наши любимые пельмешки уже долгое время. Спасибо Varus за доступную цену! Наши любимые пельмешки уже долгое время.',
          answer: 'Рады, что вам все понравилось.',
          rating: 5
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      getReviewsProductByCustomer: 'product-review/getReviewsProductByCustomer'
    }),
    reviewsAll () {
      return this.getReviewsProductByCustomer || [];
    },
    reviewsProducts () {
      return this.reviews?.filter(e => e.type === 'product') || []
    },
    reviewsRecipes () {
      return this.reviews?.filter(e => e.type === 'recipe') || []
    },
    isViewStateList () {
      return this.getReviewsProductByCustomer?.length > 0
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/px2rem";

.o-my-account-reviews {
  color: var(--black);

  @media (max-width: $mobile-max){
    margin: 0 var(--spacer-10);
  }

  @media (min-width: $tablet-min) and (max-width: $tablet-max){
    margin: 0;
  }

  .reviews-head {
    @media (max-width: $tablet-max){
      padding: var(--spacer-7) var(--spacer-25);
    }

    @media (min-width: $desktop-min) {
      padding-bottom: var(--spacer-30);
    }

    &__title {
      font-family: var(--font-family-secondary);
      font-size: var(--font-size-24);
      font-weight: var(--font-medium);
      line-height: normal;
    }
  }

  .reviews-wrap {
    background-color: var(--white);
    box-sizing: border-box;

    .reviews-empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: px2rem(530);
      box-sizing: border-box;

      @media (max-width: $mobile-max) {
        justify-content: center;
        padding: 0 var(--spacer-20);
      }

      @media (min-width: $tablet-min) and (max-width: $tablet-max){
        justify-content: center;
        padding: 0 var(--spacer-30);
      }

      @media (min-width: $desktop-min) {
        justify-content: center;
        padding: 0 var(--spacer-50);
      }

      &__outer-circle {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--light-gray);
        border-radius: 100%;
        width: px2rem(164);
        height: px2rem(164);
      }

      &__inner-circle {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--white);
        border-radius: 100%;
        width: px2rem(96);
        height: px2rem(96);
      }

      &__text {
        margin-top: var(--spacer-35);
        text-align: center;
        color: var(--dark-gray);
      }

      &__main-text {
        font-family: var(--font-family-secondary);
        font-size: var(--font-size-20);
        font-weight: var(--font-medium);
        text-align: center;
        line-height: normal;
      }

      &__description {
        margin-top: var(--spacer-15);
        font-family: var(--font-family-secondary);
        font-size: var(--font-size-14);
        font-weight: var(--font-normal);
        max-width: px2rem(700);
        text-align: center;
        line-height: normal;
        padding-bottom: var(--spacer-xs);
      }
    }

    .reviews-list-wrap {
      min-height: px2rem(505);
      box-sizing: border-box;

      @media (max-width: $mobile-max) {
        padding: var(--spacer-20) 0;
      }

      @media (min-width: $tablet-min) and (max-width: $tablet-max){
        padding: var(--spacer-20) var(--spacer-30) 0 var(--spacer-30);
      }

      @media (min-width: $desktop-min) {
        padding: var(--spacer-30) var(--spacer-50) 0 var(--spacer-50);
      }

      ::v-deep {
        .sf-tabs {
          &__title {
            background: var(--tabs-title-background);
            color: var(--black);
            font-size: var(--font-size-16);
            font-weight: var(--font-medium);

            &--active {
              border-bottom: 3px solid var(--orange);

              & + .sf-tabs__content {
                --tabs-content-border-width: 3px 0 0 0;
                width: 100%;
              }
            }
          }

          &__content__tab {
            padding: 0;
          }

          --tabs-title-z-index: 1;
          --tabs-content-order: 1;
          --tabs-title-flex: 0 0 auto;
          --tabs-title-margin: 0 var(--spacer-lg) -2px 0;
          --tabs-title-padding: 0 0 15px;
          --tabs-title-color: var(--c-text-muted);
          --tabs-title-font-size: var(--h4-font-size);
          --tabs-content-tab-padding: var(--spacer-base) 0;
          --tabs-chevron-display: none;
          --tabs-title-font-line-height: var(--font-size-19);
        }
      }

      .review-item {
        margin-top: var(--spacer-30);
      }
    }
  }

  ::v-deep {
    .products__load-more {
      display: none;
    }

  }
}
</style>
