<template>
  <div
    class="sf-content-pages"
    :class="{ 'sf-content-pages__sticky-elements--is-hide': isStickyElementsHide }"
  >
    <SfBar
      :back="isActive"
      :title="active ? title : this.$t('My profile')"
      v-if="isMobile"
      @click:back="updatePage()"
      class="sf-bar--is-sticky"
      :class="{ 'sf-bar-menu--is-sticky': !isActive }"
    >
      <template v-if="active" #close>
        <div class="sf-content-pages__close-bar" :id="'close-position-' + active" />
      </template>
    </SfBar>
    <section
      :class="{ 'sf-content-pages__section--is-active': isActive }"
      class="sf-content-pages__section"
    >
      <div class="sf-content-pages__sidebar" v-show="!isActive || isDesktop">
        <div
          v-for="(category, key) in categories"
          :key="`${category.title}-${key}`"
        >
          <h2 v-if="category.title" class="sf-content-pages__category-title">
            {{ category.title }}
          </h2>
          <SfList class="sf-content-pages__list">
            <SfListItem
              v-for="(page, keyItem) in category.items"
              :key="`${page.title}-${keyItem}`"
              class="sf-content-pages__list-item"
            >
              <!-- @slot Custom menu-item markup -->
              <slot name="menu-item" v-bind="{ updatePage, page, active }">
                <SfMenuItem
                  :class="{ 'sf-menu-item--is-active': page.slug === active }"
                  :label="page.title"
                  class="sf-content-pages__menu"
                  @click="updatePage(page.slug)"
                >
                  <template #icon>
                    <SfIcon
                      v-if="page.icon"
                      :icon="page.icon"
                      class="sf-menu-item__icon"
                      color="#686d7d"
                      size="xxs"
                    />
                  </template>
                </SfMenuItem>
              </slot>
            </SfListItem>
          </SfList>
        </div>
      </div>
      <div
        class="sf-content-pages__content"
        :class="{ 'pre-header-no-exist': !isShownPreHeaderBanner }"
        v-show="isActive"
      >
        <!-- @slot Slot for Content Page -->
        <slot />
      </div>
    </section>
  </div>
</template>
<script>
import Vue from 'vue';
import SfOContentPage from './SfOContentPage.vue';
// import SfContentCategory from '@storefront-ui/vue/src/components/organisms/SfOContentCategory.vue';
import SfList from '@storefront-ui/vue/src/components/organisms/SfList/SfList.vue';
import SfMenuItem from '@storefront-ui/vue/src/components/molecules/SfMenuItem/SfMenuItem.vue';
import SfIcon from '@storefront-ui/vue/src/components/atoms/SfIcon/SfIcon.vue';
import SfBar from '@storefront-ui/vue/src/components/molecules/SfBar/SfBar.vue';
import DeviceType from 'theme/mixins/DeviceType';
Vue.component('SfOContentPage', SfOContentPage);
// Vue.component('SfContentCategory', SfContentCategory);

export default {
  name: 'SfOContentPages',
  components: {
    SfList,
    SfMenuItem,
    SfIcon,
    SfBar
  },
  mixins: [DeviceType],
  props: {
    /**
     * Pages title
     */
    title: {
      type: String,
      default: ''
    },
    /**
     * Active page
     */
    active: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      items: []
    }
  },
  computed: {
    categories () {
      const items = [];
      const orphans = [];

      this.items.forEach((item) => {
        if (item.items) {
          if (orphans.length > 0) {
            items.push({ items: orphans });
            orphans.length = 0;
          }
          items.push(item);
        } else {
          orphans.push(item);
        }
      });

      if (orphans.length > 0) {
        items.push({ items: orphans });
      }

      return items;
    },
    isActive () {
      return this.active.length > 0;
    },
    isStickyElementsHide () {
      return this.$store.state.ui.microcart || this.$store.state.ui.searchpanel || this.$store.state.ui.isMobileCatalog || this.$store.state.ui.isMobileMenu
    },
    isShownPreHeaderBanner () {
      return this.$store.state.ui.preHeaderBanner
    }
  },
  provide () {
    const provided = {};
    Object.defineProperty(provided, 'active', {
      get: () => this.active
    });
    return { provided };
  },
  watch: {
    isMobile (mobile) {
      if (mobile) {
        this.$emit('click:change', this.active || '');
      }
    },
    isTablet (tablet) {
      if (tablet) {
        this.$emit('click:change', this.active || this.categories[0].items[0].slug);
      }
    }
  },
  methods: {
    updatePage (slug = '') {
      /**
       * Active page updated event
       *
       * @event click:change
       * @type String
       */
      this.$emit('click:change', slug);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/components/organisms/SfContentPages";
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/breakpoints";

.sf-menu-item {
  justify-content: start;
}

.sf-list .sf-list__item {
  @media (min-width: $tablet-min) {
    margin: var(--spacer-8) 0;
  }

  @media (max-width: $mobile-max) {
    padding: var(--spacer-12) var(--spacer-10) var(--spacer-10);
  }
}

.sf-content-pages {
  --content-pages-mobile-sticky-index: 4;
  height: auto;

  &__section {
    @media (max-width: $mobile-max) {
      margin: 0 0 var(--spacer-28);
    }

    @include for-tablet {
      margin: 0;
    }

    &--is-active {
      transform: none;

      @media (max-width: $mobile-max) {
        margin: 0 0 var(--spacer-28);
      }
    }
  }

  &__sidebar {
    flex: 0 0 100%;
    @include for-desktop {
      flex: 0 0 13.75rem;
      max-width: 13.75rem;
    }
  }

  &__content {
    flex: 0 0 100%;
    @include for-desktop {
      flex: 1;
    }
  }

  &__content.pre-header-no-exist {
    @media (max-width: $mobile-max) {
      margin-top: var(--spacer-50);
    }
  }

  &__close-bar {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
  }

  &__sticky-elements--is-hide {
    --content-pages-mobile-sticky-index: 0;
  }
}

.sf-bar--is-sticky {
  @media (max-width: $mobile-max) {
    position: fixed;
    z-index: var(--content-pages-mobile-sticky-index, 4);
    left: 0;
    right: 0;
    top: 0;
    height: calc(var(--bar-height, 3.125rem) + 2px);
  }
}

.sf-bar-menu--is-sticky {
  @media (max-width: $mobile-max) {
    position: static;
    z-index: 1;
  }
}

::v-deep {
  .sf-bar {
    --bar-font-size: var(--font-size-22);
    --bar-font-weight: var(--font-bold);
    --bar-font-family: var(--font-family-headers);
    text-transform: uppercase;
  }
}
</style>
