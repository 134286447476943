<template>
  <div class="order-item">
    <MOrderHeader :data-order-id="order.item_id" @click="showOrder" :order="order" />
    <div class="order-item__date" @click="showOrder">
      {{ order.date }}
    </div>
    <MOrderAddress :order="order" @click="showOrder" />
    <MOrderAmount :order="order" />
  </div>
</template>

<script>
import MOrderHeader from 'theme/components/molecules/account-orders-list-item/m-order-header.vue'
import MOrderAddress from 'theme/components/molecules/account-orders-list-item/m-order-address.vue'
import MOrderAmount from 'theme/components/molecules/account-orders-list-item/m-order-amount.vue'

export default {
  name: 'OAccountOrderListItem',
  components: {
    MOrderHeader,
    MOrderAddress,
    MOrderAmount
  },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  methods: {
    showOrder () {
      this.$emit('showOrder', this.order)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";

.order-item {
  max-width: px2rem(610);
  font-family: var(--font-family-inter);
  border-bottom: 1px solid var(--gray3);
  padding-bottom: var(--spacer-22);

  @media (max-width: $mobile-max) {
    border-bottom: 2px solid var(--gray3);
  }

  & > div {
    display: flex;
    flex-direction: row;
  }

  &__date {
    padding-top: var(--spacer-2);
    cursor: pointer;
    font-size: var(--font-size-12);
    color: var(--dark-gray);

    @media (max-width: $mobile-max) {
      padding-top: var(--spacer-8);
    }
  }
}
</style>
