<template>
  <SfLink
    :data-transaction-name="dataTransactionName"
    @click.prevent="onClick"
    class="add-new"
  >
    <span v-if="icon" class="sf-icon color-orange size-xs">
      <svg
        class="sf-icon-path"
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
      >
        <path d="M5 0V5M5 5H0M5 5V10M5 5H10" stroke="#EB6747" />
      </svg>
    </span>
    <div>{{ $t(text) }}</div>
  </SfLink>
</template>

<script>
import { SfLink } from '@storefront-ui/vue';

export default {
  name: 'AAddNewLink',
  components: {
    SfLink
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    dataTransactionName: {
      type: String,
      default: ''
    },
    icon: {
      type: Boolean,
      default: () => true
    }
  },
  methods: {
    onClick () {
      this.$emit('click');
    }
  }
};
</script>

<style lang="scss" scoped>
.add-new {
  display: flex;
  align-items: center;
  gap: var(--spacer-10);

  .color-orange {
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    background-color: rgba(235, 103, 71,.1);

    svg {
      width: var(--spacer-10);
      height: var(--spacer-10);
      margin: auto;
      fill: var(--orange);
    }
  }

  div {
    font-size: var(--font-size-14);
    color: var(--orange);
  }

  &:hover {
    cursor: pointer;
  }
}
</style>
