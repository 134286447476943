<template>
  <svg width="24" height="24" viewBox="6 3 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet">
    <circle cx="17" cy="15" r="11.25" fill="#EB6747" />
    <circle cx="17" cy="15" r="11.25" stroke="#fff" stroke-width="1.5" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.84 9a9.12 9.12 0 0 0-1.34 1.8c-.34.6-.6 1.26-.77 1.94-.47-.36-1-.65-1.54-.87a8.9 8.9 0 0 0-2.14-.58c-.08.75-.07 1.51.03 2.26.08.52.2 1.02.38 1.5a6.25 6.25 0 0 1 5.38 1.49 6.25 6.25 0 0 1 5.4-1.48c.17-.49.3-1 .36-1.5.1-.76.11-1.52.03-2.27-.73.1-1.45.3-2.13.58-.55.22-1.07.51-1.55.87a7.52 7.52 0 0 0-.76-1.94A9.2 9.2 0 0 0 16.84 9Z" fill="#fff" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.57 15.8a5.38 5.38 0 0 0 3.17 4.6c.2-1.24.75-2.4 1.57-3.32a5.52 5.52 0 0 0-4.74-1.28ZM15.3 22.14l.35.17c.2-.5.44-1 .7-1.5a5.29 5.29 0 0 0 5.76-5.02 5.54 5.54 0 0 0-4.79 1.3 5.79 5.79 0 0 0-1.88 3.56h.03a9.08 9.08 0 0 1 4.34-3.52l.1-.03a11.46 11.46 0 0 0-4.6 5.04Z" fill="#AAC91D" />
  </svg>
</template>

<script>
export default {
  name: 'ABonusIcon'
}
</script>
