<template>
  <div class="m-review-list">
    <SfOReview
      v-for="(review, i) in pageReviews"
      :key="i"
      class="product__review"
      :product="review.product_name"
      :product-link="getUrlProduct(review)"
      :product-image="getUrlImage(review)"
      :author="review.author"
      :date="review.created_at"
      :message="review.content"
      :answer="review.comments"
      :rating="getReview(review.rating)"
      :max-rating="5"
      :char-limit="190"
      :read-more-text="$t('Read more review')"
      :hide-full-text="$t('Read less review')"
    />
    <div class="after-items-space" />
    <div
      v-if="total > 1"
      class="reviews-list__pagination-wrap"
    >
      <div class="before-pagination-space" />
      <SfOPagination
        :current="currentPage"
        :visible="visible"
        :total="total"
        page-param-name="page"
        class="reviews-list__pagination"
      />
    </div>
  </div>
</template>

<script>
import SfOPagination from 'theme/components/storefront-override/SfOPagination';
import SfOReview from 'src/themes/varus/components/storefront-override/SfOReview';
import { getProductImagePath } from 'theme/helpers';

export default {
  name: 'MAccountReviewList',
  components: {
    SfOReview,
    SfOPagination
  },
  data () {
    return {
      currentPage: 1
    }
  },
  props: {
    reviews: {
      type: Array,
      default: () => []
    },
    visible: {
      type: Number,
      default: 10
    }
  },
  computed: {
    total () {
      return Math.ceil(this.reviews.length / this.visible)
    },
    pageReviews () {
      const startIndex = (this.currentPage - 1) * this.visible
      const endIndex = startIndex + this.visible
      return this.reviews.slice(startIndex, endIndex)
    }
  },
  methods: {
    setCurrentPage (newPage) {
      this.currentPage = newPage
    },
    getReview (rating) {
      return parseInt(rating) / 20;
    },
    getUrlProduct (review) {
      return review?.product_url_key || '';
    },
    getUrlImage (review) {
      return getProductImagePath(review.sku, 220, 220);
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (to) {
        if (to.query.page) {
          this.setCurrentPage(parseInt(to.query.page) || 1);
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";

.product {
  &__review {
    margin-bottom: var(--spacer-30);

    &:last-of-type {
      margin-bottom: var(--spacer-xs);
    }
  }

  &__reviews-pagination {
    margin: var(--spacer-sm) 0 0;
  }
}

.reviews-list__pagination-wrap {
  background-color:  var(--light-gray);

  @media (max-width: $mobile-max) {
    position: relative;
    margin: 0 px2rem(-10);
  }

  .sf-link--primary {
    font-size: var(--font-sm);
    padding: var(--spacer-20) 0 0;

    @media (min-width: $tablet-min) {
      padding-top: 0;
      padding-left: var(--spacer-30);
    }
  }

  .before-pagination-space {
    background-color:  var(--gray-light);
    height: var(--spacer-25);
    margin: 0 px2rem(-50);

    @include for-tablet {
      margin: 0 px2rem(-40);
    }

    @media (max-width: $mobile-max) {
      display: none;
    }
  }

  .reviews-list__pagination {
    position: relative;
    background-color:  var(--light-gray);
    align-items: center;
    justify-content: end;
    padding: var(--spacer-25);
    margin: 0 px2rem(-50);

    @include for-tablet {
      margin: 0 px2rem(-40);
    }

    @media (max-width: $mobile-max) {
      margin: 0;
      position: static;
      justify-content: center;
    }

    ::v-deep {
      .router-link-active,
      .sf-pagination__item {
        color: var(--orange);
        transition: color 0.3s ease-in-out;

        &:hover {
          color: var(--orange-hover);
        }
      }
      .sf-pagination__item--current {
        color: var(--black);
      }
      .sf-pagination__item:not(.sf-pagination__item--current):not(.sf-link) {
        color: var(--black);
      }
      .sf-pagination__item {
        &--next--disable,
        &--prev--disable {
          &:after {
            border-color: var(--dark-gray);
          }
        }
      }
    }
  }
}

.after-items-space {
  background-color: var(--white);
  height: px2rem(50);

  @media (max-width: $mobile-max) {
    height: px2rem(25);
  }
}
</style>
