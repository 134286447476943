import VueOfflineMixin from 'vue-offline/mixin'
import { mapActions, mapGetters } from 'vuex'
import Composite from '@vue-storefront/core/mixins/composite'
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import DeviceType from 'theme/mixins/DeviceType';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import { ModalList } from 'theme/store/ui/modals'
import { groups, sendToLogs } from 'theme/helpers/web-logging';
import { isServer } from '@vue-storefront/core/helpers'

export default {
  name: 'PaymentCard',
  data () {
    return {
      selectedCard: {},
      updateCardsTimer: null,
      cardsDiff: [],
      isLoading: false
    }
  },
  mixins: [
    Composite,
    VueOfflineMixin,
    DeviceType
  ],
  props: {
    preSelectedCardMask: {
      type: String,
      default: ''
    }
  },
  methods: {
    ...mapActions({
      loadPaymentCardsOptions: 'user-payment-cards/loadPaymentCardsOptions',
      callVerificationWidget: 'user-payment-cards/callVerificationWidget',
      spawnNotification: 'notification/spawnNotification',
      loadPaymentCardsList: 'user-payment-cards/loadPaymentCardsList',
      sendTokenData: 'user-payment-cards/sendTokenData',
      openModal: 'ui/openModal',
      closeModal: 'ui/closeModal'
    }),
    async getIp () {
      try {
        if (isServer) return null

        const { ip } = await fetch('/ip/lookup').then((r) => r.json())

        return ip
      } catch (e) {
        return null
      }
    },
    async addCard () {
      const response = await this.loadPaymentCardsOptions()

      if (response?.code === 200) {
        if (response.result.gateway === 'FOUR_BILL') {
          await this.addBinanceCard(response)
        } else if (response.result.gateway === 'TRANZZO') {
          await this.addTranzzoCard(response)
        } else {
          await this.addWFPCard(response)
        }
      } else {
        sendToLogs(
          groups.Payments,
          'addCard:cards_options:notLoaded',
          { code: response?.code, result: response?.result || null },
          response?.transparentId
        )

        this.spawnNotification({
          type: 'danger',
          message: String(this.$t('Error getting Wayforpay lookup verification parameters.')),
          action1: { label: String(this.$t('OK')) }
        });
      }
    },
    async prepareCards () {
      this.isLoading = true
      await this.loadCards()
      this.isLoading = false
    },
    async loadCards (setCardDefault = false) {
      try {
        const { result } = await this.loadPaymentCardsList()

        const cardsDiff = [...this.cardsDiff]
        this.cardsDiff = result.map(i => i.card_mask)

        if (setCardDefault) {
          const diff = result.filter(x => !cardsDiff.includes(x.card_mask))

          if (diff.length > 0) {
            const newCard = diff[0]

            await this.$store.dispatch('user-payment-cards/changeDefaultCard', {
              phone: newCard.phone,
              card_mask: newCard.card_mask,
              card_type: newCard.card_type,
              is_default: true
            })

            this.selectedCard = diff[0]

            return
          }
        }

        let defaultCard = null
        this.cards.forEach(card => {
          if (card.is_default) {
            defaultCard = card
          }
        })

        if (this.preSelectedCardMask) {
          this.selectedCard = this.cards.find(card => String(card.card_mask) === String(this.preSelectedCardMask)) || {}
        }

        if (!this.selectedCard || !this.selectedCard.hasOwnProperty('card_mask')) {
          this.selectedCard = defaultCard || this.cards[0]
        }
      } catch (e) {
        sendToLogs(
          groups.Payments,
          'addCard:loadList:error',
          { message: e.message }
        )
      }
    },
    async updateCardsByInterval () {
      if (isServer) return
      await this.loadCards(true)

      /**
       * The starting value of the counter is 6. Due to the fact that we repeat the request every 20 seconds for 2 minutes.
       * @type {number}
       */
      let counter = 6
      this.updateCardsTimer = setInterval(() => {
        this.loadCards(true)

        if (counter > 1) {
          counter--
        } else {
          clearInterval(this.updateCardsTimer)
        }
      }, 20000)
    },
    async addTranzzoCard ({ result: parameters }) {
      this.openModal({ name: ModalList.TranzzoWidgetPopup })
      await this.addTranzzoScript()
      const customerIp = await this.getIp()

      const handleToken = async function (tokenData) {
        try {
          await this.sendTokenData({ tokenData, phone: parameters.client_phone });
          this.closeTranzzoWidget();
          this.updateCardsByInterval();
        } catch (error) {
          console.error('Error handling token data:', error);
        }
      }.bind(this);

      const handleError = function (message) {
        sendToLogs(
          groups.Payments,
          'addCard:tranzzo:error',
          { message }
        )

        this.spawnNotification({
          type: 'danger',
          message: this.$t(String(message) || 'Authorization failed')
        });
        this.closeTranzzoWidget();
      }.bind(this);

      window.Tranzzo.init({
        key: parameters.widget_key,
        amount: 1,
        mode: 'inline',
        style: 'varus',
        template: 'varus',
        customer_id: this.currentUser.external_user_id,
        customer_fname: this.currentUser?.firstname || null,
        customer_lname: this.currentUser?.lastname || null,
        customer_email: this.currentUser?.email || null,
        customer_phone: this.currentUser?.phone || null,
        customer_country: 'Ukraine',
        customer_ip: customerIp,
        lang: 'uk',
        locale: this.getTranzzoLocale(),
        selector: 'widget-checkout',
        properties: {
          showSubmit: false,
          sale: false
        },
        async onToken (tokenData) {
          handleToken(tokenData);
        },
        onError: handleError
      }).open();
    },
    getTranzzoLocale () {
      return {
        en: {
          cardNumber: 'Card number',
          expiryDate: 'Expiry Date',
          cvv: 'CVV',
          submit: 'Pay',
          yy: 'YY',
          mm: 'MM'
        },
        uk: {
          cardNumber: 'Номер картки',
          expiryDate: 'Термін дїї',
          cvv: 'CVV',
          submit: 'Оплатити',
          yy: 'РР',
          mm: 'ММ'
        }
      }
    },
    closeTranzzoWidget () {
      this.closeModal({ name: ModalList.TranzzoWidgetPopup })
    },
    addTranzzoScript () {
      if (document.getElementById('tranzzo-script')) {
        return
      }

      const tranzzoScript = document.createElement('script')
      tranzzoScript.src = 'https://gcdn.tranzzo.com/widget.js'
      tranzzoScript.id = 'tranzzo-script'
      tranzzoScript.defer = true
      document.body.appendChild(tranzzoScript)

      return new Promise((resolve) => {
        const waitTranzzo = () => {
          // eslint-disable-next-line
          if (window.Tranzzo) {
            return resolve()
          }

          setTimeout(waitTranzzo, 1000)
        }

        waitTranzzo()
      })
    },
    async addBinanceCard (response) {
      EventBus.$emit('add-binance-card', response?.result?.service_url)
    },
    async addWFPCard (response) {
      await this.addWFPScript()

      const parameters = response.result
      parameters.requestType = 'VERIFY'
      parameters.language = currentStoreView().i18n.defaultLanguage

      if (this.isMobile) {
        parameters.straightWidget = true
      }

      await this.callVerificationWidget({
        // eslint-disable-next-line
        wayforpay: new Wayforpay(),
        parameters
      })

      await this.updateCardsByInterval()
    },
    addWFPScript () {
      if (document.getElementById('wfp-script')) {
        return
      }

      const wfpScript = document.createElement('script')
      wfpScript.src = 'https://secure.wayforpay.com/server/pay-widget.js'
      wfpScript.id = 'wfp-script'
      wfpScript.defer = true
      document.body.appendChild(wfpScript)

      return new Promise((resolve) => {
        const waitWayforpay = () => {
          // eslint-disable-next-line
          if (window.Wayforpay) {
            return resolve()
          }

          setTimeout(waitWayforpay, 1000)
        }

        waitWayforpay()
      })
    }
  },
  computed: {
    ...mapGetters({
      cards: 'user-payment-cards/getPaymentCards',
      cardsLoaded: 'user-payment-cards/getPaymentCardsLoaded',
      currentUser: 'user/currentUser'
    })
  },
  beforeMount () {
    this.prepareCards()
    EventBus.$on('add-binance-card:close', this.updateCardsByInterval)
  },
  beforeDestroy () {
    this.closeTranzzoWidget()
    clearInterval(this.updateCardsTimer)
    EventBus.$off('add-binance-card:close', this.updateCardsByInterval)
  }
}
