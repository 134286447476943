<template>
  <div class="m-account-bonus-summary">
    <div class="state-block">
      <div class="orders-period" >
        <span class="orders-period__title">
          {{ $t('Orders by') }}
        </span>
        <SfOSelect
          class="orders-period__select"
          v-model="selectedYear"
          @change="handlerYear"
          :size="10"
        >
          <SfSelectOption
            v-for="(item, i) in years"
            :key="i"
            :value="item"
            @change="setYearSummary"
          >
            {{ item }}
          </SfSelectOption>
        </SfOSelect>
        <span
          v-if="monthTitle"
          class="orders-month"
        >
          {{monthTitle}}
        </span>
      </div>
      <div class="orders-state">
        <div class="orders-state__orders">
          <div class="orders-state__title">{{ $t('Purchases') }}</div>
          <div class="orders-state__value">{{ summaryHeader.orders }}</div>
        </div>
        <div class="orders-state__total">
          <div class="orders-state__title">{{ $t('For amount') }}</div>
          <div class="orders-state__amount">
            <div class="orders-state__value">{{ summaryHeader.amount }}</div>
            <div class="orders-state__unit">{{ currency }} </div>
          </div>
        </div>
        <div class="orders-state__saved">
          <div class="orders-state__title">{{ $t('Saved') }}</div>
          <div class="orders-state__amount">
            <div class="orders-state__value">{{ summaryHeader.saved }}</div>
            <div class="orders-state__unit">{{ currency }} </div>
          </div>
        </div>
      </div>
    </div>
    <div class="history-block">
      <div class="history-block__chart">
        <no-ssr>
          <MBarChart
            ref="mBarChart"
            :chart-data="chartData"
            :chart-options="chartOptions"
            @chartClick="setHeaderMonthData"
          />
        </no-ssr>
      </div>
      <div
        @click="goToOrdersHistory"
        class="history-block__link"
      >
        {{ $t('View bonus account history') }}
      </div>
    </div>
    <div v-if="summaryLoading" class="data-loader__wrap">
      <SfLoader class="data-loader"/>
    </div>
  </div>
</template>

<script>
import SfOSelect from 'theme/components/storefront-override/SfOSelect'
import SfLoader from 'theme/components/storefront-override/SfLoader'
import NoSSR from 'vue-no-ssr'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'OMyAccountBonusCard',
  components: {
    'no-ssr': NoSSR,
    MBarChart: () => process.browser ? import('theme/components/molecules/m-bar-chart') : null,
    SfLoader,
    SfOSelect
  },
  data () {
    return {
      selectedYear: String(new Date().getFullYear()),
      currency: 'грн',
      chartOptions: {
        scales: {
          yAxios: {
            ticks: {
              display: false
            }
          }
        },
        events: ['click', 'touchstart'],
        plugins: {
          legend: {
            display: false
          }
        }
      },
      summaryHeader: {
        orders: 0,
        amount: 0,
        saved: 0
      },
      loadOnYearSelect: false,
      selectedMonth: -1,
      labels: [
        this.$t('January'),
        this.$t('February'),
        this.$t('March'),
        this.$t('April'),
        this.$t('May'),
        this.$t('June'),
        this.$t('July'),
        this.$t('August'),
        this.$t('September'),
        this.$t('October'),
        this.$t('November'),
        this.$t('December')
      ]
    }
  },
  computed: {
    ...mapState({
      summary: state => state.bonusCard.bonusSummary || {},
      summaryLoading: state => state.bonusCard.bonusSummaryLoading
    }),
    ...mapGetters({
      bonusCard: 'bonusCard/getBonusCard'
    }),
    monthTitle () {
      return this.selectedMonth === -1 ? '' : this.chartData.labels[this.selectedMonth]
    },
    chartData () {
      return {
        labels: this.labels,
        datasets: [
          {
            label: this.$t('Orders Total'),
            backgroundColor: 'rgba(250, 120, 89, 0.5)',
            hoverBackgroundColor: 'rgba(250, 120, 89, 1)',
            data: this.monthData
          }
        ]
      }
    },
    monthData () {
      const apiData = this.summary?.data_by_month || []

      return this.labels.map((num, index) => {
        return apiData.find(e => e.month - 1 === index)
          ? { x: apiData[index]?.month || 0, y: apiData[index]?.orders_amount || 0 }
          : { x: 0, y: 0 }
      })
    },
    years () {
      return [0, 1, 2, 3]
        .map(e => String(new Date().getFullYear() - e))
    }
  },
  watch: {
    summaryLoading (value, oldValue) {
      if (oldValue && !value) {
        const monthNum = this.loadOnYearSelect ? -1 : new Date().getMonth()
        this.$nextTick(() => {
          this.loadOnYearSelect = false
          this.$refs.mBarChart.setActiveElement(monthNum)
        })
      }
    }
  },
  methods: {
    ...mapActions({
      loadBonusSummary: 'bonusCard/loadBonusSummary'
    }),
    handlerYear () {
      this.$refs.mBarChart.resetAllTooltips()
      this.loadOnYearSelect = true
      this.loadBonusSummary(this.selectedYear)
    },
    goToOrdersHistory () {
      this.$router.push(this.localizedRoute('/my-account/orders'))
    },
    setYearSummary () {
      this.summaryHeader = {
        orders: this.summary.total_orders,
        amount: this.summary.total_amount,
        saved: this.summary.total_saved_amount
      }
    },
    setHeaderMonthData (monthNum) {
      this.selectedMonth = monthNum

      if (monthNum === -1) {
        this.setYearSummary()
        return
      }

      if (!this.summary.data_by_month?.length) return

      this.summaryHeader = {
        orders: this.summary.data_by_month[monthNum]?.orders_amount,
        amount: this.summary.data_by_month[monthNum]?.orders_total,
        saved: this.summary.data_by_month[monthNum]?.saved_amount
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";
.m-account-bonus-summary {
  position: relative;

  .orders-period {
    font-family: var(--font-family-secondary);
    font-weight: var(--font-medium);
    font-size: var(--font-size-16);
    line-height: var(--font-size-20);
    margin-top: var(--spacer-30);

    @media (min-width: $tablet-min) {
      margin-top: var(--spacer-50);
    }

    &__select {
      display: inline-block;
      margin-left: 3px;
    }

    .sf-select__selected {
      color: var(--orange);
      font-weight: var(--font-medium);
      font-size: var(--font-size-16);
      line-height: var(--font-size-20);
    }

    &__title {
      color: var(--black);
    }

    &__period {
      color: var(--orange);
      margin-left: var(--spacer-8);
    }
  }

  .orders-state {
    margin-top: var(--spacer-20);
    display: flex;
    background-color: var(--light-gray);
    border-radius: var(--spacer-5);
    height: var(--spacer-46);
    font-family: var(--font-family-secondary);
    padding: var(--spacer-10) 0;
    justify-content: center;
    align-items: start;

    @media (min-width: $tablet-min) {
      padding: 0;
      align-items: center;
      height: var(--spacer-60);
    }

    &__orders {
      flex: 1 1 25%;
      border-right: 1px solid var(--gray3);
      display: flex;
    }

    &__total {
      flex: 1 1 40%;
      border-right: 1px solid var(--gray3);
    }

    &__saved {
      flex: 1 1 35%;
    }

    &__amount {
      display: flex;
      align-items: baseline;
    }

    &__value {
      font-family: var(--font-family-secondary);
      font-weight: var(--font-medium);
      font-size: var(--font-size-20);
    }

    &__unit {
      margin-left: var(--spacer-3);
      font-family: var(--font-family-secondary);
      font-weight: var(--font-normal);
      font-size: var(--font-size-12);
      line-height: var(--font-size-14);
    }

    &__title {
      font-family: var(--font-family-secondary);
      font-weight: var(--font-normal);
      font-size: var(--font-size-12);
      line-height: var(--font-size-14);
      color: var(--dark-gray);

      @media (min-width: $tablet-min) {
        margin-right: var(--spacer-10);
      }
    }
  }

  .orders-state > div {
    display: flex;
    justify-content: center;
    align-items: baseline;
    flex-direction: column;
    padding-left: var(--spacer-10);

    @media (min-width: $tablet-min) {
      flex-direction: row;
    }
  }

  .history-block {
    margin-top: var(--spacer-35);

    &__chart {
      max-height: px2rem(450);
    }

    &__link {
      margin-top: var(--spacer-40);
      font-family: var(--font-family-secondary);
      font-weight: var(--font-normal);
      font-size: var(--font-size-14);
      line-height: var(--font-size-17);
      color: var(--orange);
      cursor: pointer;

      &:hover {
        color: var(--orange-hover);
      }
    }
  }

  .data-loader__wrap {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    opacity: .8;
  }

  .data-loader {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ::v-deep {
    .sf-select__selected {
      --select-option-color: var(--orange);
    }

    @media (max-width: $tablet-max) {
      .sf-select__dropdown {
        left: var(--spacer-20);
        right: var(--spacer-20);
        bottom: var(--spacer-80);
        width: unset;
        background: none;
        z-index: calc(var(--sf-overlay-index, 3) + 1);

        .sf-select-option {
          padding:var(--spacer-20) var(--spacer-24);
        }

        .sf-select-option:not(:last-child) {
          border-bottom: 1px solid var(--line-gray);
        }
      }

      .sf-select__options {
        border-radius: var(--spacer-5);
        background: var(--c-white);
      }

      .sf-select__cancel {
        margin-top: var(--spacer-8);
        border-radius: var(--spacer-5);
        background: var(--c-white);
      }
    }
  }
}
</style>
