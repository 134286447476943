<template>
  <div>
    <!--        user info-->
    <div v-if="page.type === 'user'" class="account-user">
      <SfImage
        :src="avatarUrl"
        :alt="$t('Account image')"
        class=""
        :width="36"
      />
      <div class="account-user__info">
        <div class="account-user__info_name">
          {{ user.name }}
        </div>
        <div class="account-user__info_phone">
          {{ user.phone }}
        </div>
      </div>
      <span class="sf-menu-item__mobile-nav-icon sf-icon user-info--nav-icon" />
    </div>
    <hr v-if="page.type === 'user'" class="account-menu__divider divider-user">
    <!--    end user info-->
    <!--        menu item -->
    <SfMenuItem
      v-else-if="page.type === 'item'"
      :class="{ 'sf-menu-item--is-active': page.slug === active }"
      :label="page.title"
      class="account-menu-item"
    >
      <template #icon>
        <SfIcon
          v-if="page.icon"
          :class="[`account-menu-item__icon-${page.slug}`, { 'account-menu-item__icon--is-active': page.slug === active }]"
          class="account-menu-item__icon"
        />
      </template>
      <template #label>
        <div
          :class="{ 'account-menu-item__label--is-active': page.slug === active, 'account-menu-item__label--is-small': isItemSmall }"
          class="account-menu-item__label item-label "
        >
          {{ page.title }}
        </div>
      </template>
      <template #count>
        <div
          v-if="page.slug !== 'reviews'"
          class="account-menu-item__quantity-wrap"
        >
          <div
            v-if="page.quantity"
            class="account-menu-item__quantity-value"
          >
            {{ page.quantity }}
          </div>
        </div>
        <div
          v-else-if="getReviewsCount !== 0"
          class="account-menu-item__quantity-wrap"
        >
          <div class="account-menu-item__quantity-value">
            {{ getReviewsCount }}
          </div>
        </div>
      </template>
    </SfMenuItem>
    <!--    end menu item -->
    <!--        menu bonus-card-->
    <MAccountBonusCard v-if="page.type === 'bonus-card'" class="bonus-card-item" />
    <!--     end menu bonus-card -->
    <!--        menu logout -->
    <hr v-if="page.type === 'logout'" class="account-menu__divider divider-logout">
    <SfMenuItem
      v-if="page.type === 'logout'"
      :class="{ 'sf-menu-item--is-active': page.title === active }"
      :label="page.title"
      data-transaction-name="Logout"
      @click="$emit('logout')"
    >
      <template #label>
        <div class="account-menu-item__label logout-label">
          {{ page.title }}
        </div>
      </template>
      <template #mobile-nav-icon>
        <div />
      </template>
    </SfMenuItem>
    <!--    end menu logout -->
  </div>
</template>
<script>
import DeviceType from 'theme/mixins/DeviceType';
import MAccountBonusCard from 'theme/components/molecules/m-account-bonus-card';
import { SfMenuItem, SfIcon, SfImage } from '@storefront-ui/vue';
import { mapGetters } from 'vuex';

export default {
  name: 'MAccountMenuItem',
  components: {
    MAccountBonusCard,
    SfMenuItem,
    SfIcon,
    SfImage
  },
  mixins: [DeviceType],
  props: {
    page: {
      type: Object,
      default: () => ({})
    },
    user: {
      type: Object,
      default: () => ({})
    },
    active: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      smallMenuItems: ['promo-codes', 'reviews', 'addresses', 'payment-cards', 'devices', 'notifications']
    }
  },
  computed: {
    ...mapGetters({
      getUserAvatar: 'userInfo/getUserAvatar',
      getReviewsProductByCustomer: 'product-review/getReviewsProductByCustomer'
    }),
    isItemSmall () {
      return this.smallMenuItems.includes(this.page.slug)
    },
    avatarUrl () {
      return this.getUserAvatar ? this.getUserAvatar : '/assets/account/avatar.svg'
    },
    getReviewsCount () {
      return this.getReviewsProductByCustomer.length;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/breakpoints";
@import "~theme/css/fonts";

.sf-bar__title {
  font-size: var(--font-size-20);
  font-weight: var(--font-medium);
  text-align: left;
}

.account-menu__divider {
  margin-bottom: var(--spacer-12);
  height: 1px;
  border: none;
  background-color: var(--light-gray);

  @include for-desktop {
    margin-left: -15px;
    margin-right: -15px;
  }

  @media (max-width: $mobile-max) {
    display: none;
  }
}

.account-menu-item {
  &__icon.sf-icon {
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  &__icon {
    &:before {
      color: var(--black);
    }

    &-orders {
      @include font-icon(var(--icon-cart));

      &:before {
        font-size: var(--font-size-24);
      }
    }

    &-notifications {
      @include font-icon(var(--icon-notification));

      &:before {
        font-size: var(--font-size-24);
      }
    }

    &-wishlist {
      @include font-icon(var(--icon-heart));

      &:before {
        font-size: var(--font-size-18);
      }
    }

    &-reviews {
      @include font-icon(var(--icon-reviews));

      &:before {
        font-size: var(--font-size-24);
      }
    }

    &-viewed {
      @include font-icon(var(--icon-eye));

      &:before {
        font-size: var(--font-size-20);
      }
    }

    &-payment-cards {
      @include font-icon(var(--icon-credit-card));

      &:before {
        font-size: var(--font-size-18);
      }
    }
  }

  &__icon--is-active {
    &:before {
      color: var(--orange);
    }
  }

  &__label {
    color: var(--black);

    @media (max-width: $mobile-max) {
      padding: 0 var(--spacer-12) 0 var(--spacer-18);
      font-size: var(--font-base);
    }

    @media (min-width: $tablet-min) {
      padding: var(--spacer-10) var(--spacer-12);
      letter-spacing: -0.5px;
      font-size: var(--font-size-14);
    }

    font-family: var(--font-family-secondary);
    font-weight: var(--font-normal);
    line-height: var(--spacer-sm);

    &.logout-label {
      padding-left: 0;
      padding-top:var(--spacer-10);
      letter-spacing: -0.2px;
      color: var(--c-primary);

      @media (max-width: $mobile-max) {
        font-size: var(--font-size-16);
      }
    }
  }

  &__label--is-active {
    color: var(--orange);
  }

  &__quantity-wrap {
    display: flex;
    flex-grow: 1;
    justify-content: start;
    align-items: center;
  }

  &__quantity-value {
    background-color: var(--orange-light2);
    color: var(--orange);
    padding: 0 var(--spacer-xs);
    border-radius: 32px;
    font-family: var(--font-family-secondary);
    font-weight: var(--font-normal);
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: $mobile-max) {
      height: 30px;
      font-size: var(--font-base);
    }

    @media (min-width: $tablet-min) {
      height: 23px;
      font-size: var(--font-size-14);
    }
  }

  ::v-deep {
    .sf-menu-item__mobile-nav-icon {
      --icon-size: var(--spacer-7)!important;

      @include for-tablet {
        display: none;
      }
    }

    .sf-menu-item__mobile-nav-icon svg {
      stroke: var(--black);
      stroke-width: 5;
    }
  }
}
.sf-menu-item--is-active {
  color: var(--c-primary);
}

.bonus-card-item {
  margin-top: var(--spacer-15);
  margin-bottom: var(--spacer-12);

  @media (max-width: $mobile-max) {
    margin-top: var(--spacer-15);
    margin-bottom: var(--spacer-18);
  }
}

.account-user {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  color: var(--black);
  cursor: pointer;

  @media (max-width: $mobile-max) {
    padding-top: var(--spacer-8);
    padding-bottom: var(--spacer-8);
  }

  @include for-tablet {
    margin-top: var(--spacer-20);
    margin-bottom: var(--spacer-20);
  }

  @include for-desktop {
    margin-top: var(--spacer-20);
    margin-bottom: var(--spacer-20);
  }

  &__info {
    flex-direction: column;
    margin-left: var(--spacer-10);
    font-family: var(--font-family-secondary);

    &_name {
      font-size: var(--font-size-18);
      font-weight: var(--font-medium);
      margin-bottom: var(--spacer-7);
      line-height: var(--font-size-19);
      word-break: break-all;
    }

    &_phone {
      font-size: var(--font-size-13);
      font-weight: var(--font-normal);
      line-height: var(--font-size-16);

      @media (max-width: $mobile-max) {
        font-size: var(--font-size-14);
      }
    }
  }

  .user-info--nav-icon {
    margin-left: auto;
    @include font-icon(var(--icon-arrow-bold-right));

    &:before {
      font-size: var(--font-size-8);
      color: var(--black);
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    @include for-tablet {
      display: none;
    }

    @include for-desktop {
      display: none;
    }
  }

  ::v-deep {
    .sf-image {
      border-radius: 50%;
      flex-shrink: 0;

      img {
        display: block;
        object-fit: cover;
        max-width: 52px;
        width: 52px;
        height: 52px;

        @media (min-width: $tablet-min) {
          max-width: 36px;
          width: 36px;
          height: 36px;
        }
      }
    }
  }
}
</style>
