<template>
  <div class="o-my-account-wishlist">
    <OMyAccountWishlistHeader
      v-if="isTabletOrDesktop"
      @createNewWishlist="openModalCreateNewWishlist"
    />
    <div v-if="wishlists" class="o-my-account-wishlist-content">
      <OCartWishlist
        class="o-my-account-wishlist-groups"
        :is-show-edit-action="true"
      />
    </div>
  </div>
</template>

<script>
import OCartWishlist from 'theme/components/organisms/o-cart-wishlist'
import OMyAccountWishlistHeader from 'theme/components/organisms/o-my-account-wishlist-header.vue'
import DeviceType from 'theme/mixins/DeviceType'
import { mapActions, mapGetters } from 'vuex'
import { ModalList } from 'theme/store/ui/modals'

export default {
  name: 'OMyAccountWishList',
  components: {
    OCartWishlist,
    OMyAccountWishlistHeader
  },
  mixins: [
    DeviceType
  ],
  computed: {
    ...mapGetters({
      wishlists: 'wishlist/getWishlists'
    }),
    isTabletOrDesktop () {
      return this.isTablet || this.isDesktop;
    }
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
      setModeCreateNewGroup: 'wishlist/setModeCreateNewGroup'
    }),
    async openModalCreateNewWishlist () {
      await this.setModeCreateNewGroup(true)
      this.openModal({ name: ModalList.CreateWishlist })
    }
  }
};
</script>

<style lang="scss">
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";

.o-my-account-wishlist {
  padding: 10px;
  position: relative;
  z-index: 0;

  @media (min-width: $tablet-min) {
    padding: 0;
  }

  &-content {
    background-color: var(--white);
    box-sizing: border-box;

    @include for-tablet {
      padding: var(--spacer-40);
    }

    @include for-desktop {
      padding: var(--spacer-30) var(--spacer-50);
    }
  }
}
</style>
