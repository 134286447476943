<template>
  <div class="o-my-account-payment-card">
    <div class="header-add-action" v-if="isMobile">
      <SfButton
        class="sf-button--pure sf-bar__icon header-add-action__button"
        data-transaction-name="Add Payment Card"
        @click="addCard"
      />
    </div>
    <div class="payment-cards-head" v-if="isDesktop">
      <div class="payment-cards-head__title">
        {{ $t('Payment cards') }}
      </div>
      <div
        class="payment-cards-head__action"
        data-transaction-name="Add Payment Card"
        @click="addCard"
      >
        <span class="action-button" />
        <div class="action-text">
          {{ $t("Add card") }}
        </div>
      </div>
    </div>
    <div class="payment-cards-head" v-if="isTablet">
      <div
        class="payment-cards-head__action"
        data-transaction-name="Add Payment Card"
        @click="addCard"
      >
        <div class="action-button" />
        <div class="action-text">
          {{ $t("Add card") }}
        </div>
      </div>
    </div>
    <div class="payment-cards-wrap">
      <div v-if="viewState === 'loading'" class="cards-loading">
        <SfLoader />
      </div>
      <div v-else-if="viewState === 'addCardView'" class="cards-empty">
        <div class="cards-empty__text">
          {{ $t('empty card text') }}
        </div>
        <div class="cards-empty__action">
          <SfButton
            class="sf-button--primary"
            data-transaction-name="Add Payment Card"
            @click="addCard"
          >
            {{ $t("Add card") }}
          </SfButton>
        </div>
      </div>
      <div v-else class="cards-list-wrap">
        <div
          class="cards-list"
          :class="{
            'cards-list-radio-disabled': isRadioDisabled
          }"
        >
          <MPaymentCard
            v-for="(card, index) in cards"
            @handler-toggle-cards="handlerToggleCards"
            :key="index"
            :card="card"
            class="card-margin"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DeviceType from 'theme/mixins/DeviceType';
import PaymentCard from 'theme/mixins/PaymentCard';
import { SfButton } from '@storefront-ui/vue';
import SfLoader from 'src/themes/varus/components/storefront-override/SfLoader'
import MPaymentCard from '../molecules/m-payment-card';

export default {
  name: 'OMyAccountPaymentCard',
  components: { MPaymentCard, SfButton, SfLoader },
  mixins: [
    DeviceType,
    PaymentCard
  ],
  data () {
    return {
      isRadioDisabled: false
    }
  },
  computed: {
    viewState () {
      if (!this.cardsLoaded) return 'loading'
      return this.cards.length ? 'showCardsView' : 'addCardView'
    }
  },
  methods: {
    handlerToggleCards (status) {
      this.isRadioDisabled = status
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/breakpoints";
@import '~theme/css/fonts';

.o-my-account-payment-card {
  .header-add-action {
    position: fixed;
    top: var(--spacer-9);
    right: var(--spacer-20);
    justify-content: flex-end;
    display: flex;
    z-index: var(--content-pages-mobile-sticky-index, 1);

    .header-add-action__button {
      @include font-icon(var(--icon-plus));

      &:before {
        color: var(--black);
        font-size: var(--font-size-16);
      }
    }
  }

  .payment-cards-head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: $tablet-max){
      padding: 7px var(--spacer-25);
    }

    @media (min-width: $desktop-min) {
      padding-bottom: 30px;
    }

    &__title {
      font-family: var(--font-family-secondary);
      font-size: var(--font-size-24);
      font-weight: var(--font-medium);
      line-height: normal;
    }

    &__action {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: var(--font-family-secondary);
      font-weight: var(--font-normal);
      color: var(--c-primary);

      .action-button {
        width: 24px;
        height: 24px;
        border-radius: 100%;
        background-color: var(--orange-light2);
        margin-right: var(--spacer-10);
        display: flex;
        justify-content: center;
        align-items: center;
        @include font-icon(var(--icon-plus));

        &:before {
          font-size: var(--font-size-10);
          color: var(--orange);
        }
      }
      .action-text {
        font-weight: var(--font-normal);
        font-size: var(--font-size-14);
      }
    }
  }

  .payment-cards-wrap {
    background-color: var(--white);
    box-sizing: border-box;

    .cards-empty {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;

      @include font-icon(var(--icon-credit-card));

      &:before {
        font-size: var(--font-size-30);
        color: var(--black);
        background: var(--white);
        width: 70px;
        height: 70px;
        line-height: 70px;
        text-align: center;
        border-radius: 50%;
        border: 25px solid var(--light-gray);
      }

      @media (min-width: $tablet-min) {
        &:before {
          font-size: var(--font-size-30);
          color: var(--black);
          background: var(--white);
          width: 100px;
          height: 100px;
          line-height: 100px;
          text-align: center;
          border-radius: 50%;
          border: 32px solid var(--light-gray);
        }
      }

      @media (max-width: $mobile-max) {
        padding: var(--spacer-70) var(--spacer-20);
      }

      @include for-tablet {
        padding: var(--spacer-100) var(--spacer-30);
      }

      @include for-desktop {
        padding: var(--spacer-100) var(--spacer-50);
      }

      &__text {
        font-family: var(--font-family-secondary);
        font-size: var(--font-size-14);
        font-weight: var(--font-normal);
        max-width: 420px;
        text-align: center;
        line-height: normal;
        margin: var(--spacer-40) 0 var(--spacer-35);
        color: var(--dark-gray);
      }

      &__action {
        width: 100%;

        button {
         font-size: var(--font-size-18);
         line-height: var(--font-size-24);
         margin: 0 auto;

          @media (max-width: $mobile-max) {
            --button-width: 100%;
          }

          @media (min-width: $tablet-min) {
            --button-width: 245px;
          }
        }
      }
    }

    .cards-list-wrap {
      min-height: 505px;
      box-sizing: border-box;

      @media (max-width: $mobile-max) {
        padding: var(--spacer-20);
      }

      @include for-tablet {
        padding: var(--spacer-30);
      }

      @include for-desktop {
        padding: var(--spacer-50);
      }

      .cards-list {
        display: flex;
        flex-wrap: wrap;
        margin: -20px;

        @include for-mobile {
          justify-content: center;
        }

        &-radio-disabled {
          ::v-deep {
            .sf-radio__container {
              pointer-events: none;
              opacity: .4;
            }
          }
        }

        .card-margin {
          margin: var(--spacer-20)
        }
      }
    }

    .cards-loading {
      min-height: 505px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: $mobile-max) {
        padding: var(--spacer-20);
      }

      @include for-tablet {
        padding: var(--spacer-30);
      }

      @include for-desktop {
        padding: var(--spacer-50);
      }
    }
  }
}
</style>
