<template>
  <div v-if="isActive" class="sf-content-page">
    <!-- @slot Custom page markup -->
    <slot />
  </div>
</template>
<script>
export default {
  name: 'SfOContentPage',
  props: {
    /**
     * Page title
     */
    title: {
      type: String,
      default: ''
    },
    /**
     * Page nav slug
     */
    slug: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    quantity: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: 'item'
    }
  },
  inject: ['provided'],
  computed: {
    isActive () {
      return this.provided.active === this.slug;
    }
  },
  mounted () {
    const item = {
      title: this.title,
      slug: this.slug,
      icon: this.icon,
      quantity: this.quantity,
      type: this.type
    };
    this.$parent.items.push(item);
  }
};
</script>
