// Downscale image to the given width and height.
export function downscaleImage (sourceBase64Image, imageType = 'image/jpeg', maxWidth = 100, maxHeight = 100) {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas')
    const img = new Image()

    img.src = sourceBase64Image

    img.onload = () => {
      let width = img.width
      let height = img.height

      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else if (height > maxHeight) {
        width *= maxHeight / height;
        height = maxHeight;
      }

      canvas.width = Math.round(width);
      canvas.height = Math.round(height);
      canvas.getContext('2d').drawImage(img, 0, 0, width, height)

      resolve(canvas.toDataURL(imageType, 1))
    }

    img.onerror = error => {
      reject(error)
    }
  })
}
