<template>
  <div class="o-my-account-profile">
    <SfHeading :level="4" :title="$t('Profile')" class="account-head" />
    <MUpdatePersonalData />
  </div>
</template>

<script>
import {
  SfHeading
} from '@storefront-ui/vue';
import MUpdatePersonalData from 'theme/components/molecules/m-update-personal-data'

export default {
  name: 'OMyAccountProfile',
  components: {
    MUpdatePersonalData,
    SfHeading
  }
};
</script>

<style lang="scss" scoped>
 .account-head {
  display: block;
  text-align: left;
  padding-bottom: 30px;

  ::v-deep .sf-heading__title {
    line-height: normal;
  }
}
</style>
