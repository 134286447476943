<template>
  <div class="order-item__amount">
    <div class="order-item__amount-amount">
      {{ order.amount }} грн
    </div>
    <div
      v-if="!isZeroValue"
      class="order-item__amount-bonus"
    >
      <ABonusIcon />
      <span class="bonus-value">
        +{{ order.bonus_credit }}₴
      </span>
      <span class="bonus-title">
        {{ $t('bonuser') }}
      </span>
    </div>
  </div>
</template>

<script>
import { getIsZeroValue } from 'theme/helpers/orders';
import ABonusIcon from 'theme/components/atoms/ABonusIcon.vue';

export default {
  name: 'MOrderAmount',
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  components: {
    ABonusIcon
  },
  computed: {
    isZeroValue () {
      return getIsZeroValue(this.order.bonus_credit);
    }
  }
}
</script>

<style lang="scss" scoped>
.order-item__amount {
  margin-top: var(--spacer-10);
  justify-content: start;

  &-amount {
    font-size: var(--font-size-16);
    font-weight: var(--font-medium);
    color: var(--black)
  }

  &-bonus {
    font-size: var(--font-size-14);
    display: flex;
    align-items: center;
    height: var(--spacer-24);
    background-color: var(--orange-light-background);
    border-radius: var(--spacer-22);
    margin: 0 0 0 var(--spacer-10);
    color: var(--orange);

    .bonus-value {
      font-size: var(--font-size-14);
      font-weight: var(--font-medium);
      padding: 0 var(--spacer-5);
    }

    .bonus-title {
      font-size: var(--font-size-10);
      font-weight: var(--font-normal);
      padding-right: var(--spacer-5);
    }
  }
}
</style>
