<template>
  <div class="sf-rating">
    <div
      v-if="summaryRating"
      class="summary-rating"
    >
      <div class="summary-rating-items">
        <SfIcon
          v-for="index in 5"
          :key="index"
          size="0.875rem"
          class="sf-rating__icon sf-rating__icon--negative"
          icon="star"
        >
          <svg
            width="14"
            height="13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M7 .33 9.19 4l4.15.95-2.8 3.21.38 4.24L7 10.72l-3.92 1.67.38-4.24-2.8-3.21 4.15-.95L7 .33Z" stroke="#FAD656" />
          </svg>
        </SfIcon>
      </div>
      <div
        class="summary-rating-items summary-rating-items__positive"
        :style="{ width: `calc(${score} * 20%)` }"
      >
        <SfIcon
          v-for="index in 5"
          :key="index"
          size="0.875rem"
          class="sf-rating__icon sf-rating__icon--positive"
          icon="star"
        >
          <svg
            width="14"
            height="13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M7 .33 9.19 4l4.15.95-2.8 3.21.38 4.24L7 10.72l-3.92 1.67.38-4.24-2.8-3.21 4.15-.95L7 .33Z" stroke="#FAD656" fill="#FAD656" />
          </svg>
        </SfIcon>
      </div>
    </div>
    <div
      v-else
      class="rating-wrapper"
    >
      <!--@slot custom icon for finalScore. Provide single icon that will be automatically repeated -->
      <slot
        v-for="index in parseInt(finalScore, 10)"
        name="icon-positive"
      >
        <SfIcon
          :key="`p${index}`"
          size="0.875rem"
          class="sf-rating__icon"
          icon="star"
        >
          <svg
            width="14"
            height="13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M7 .33 9.19 4l4.15.95-2.8 3.21.38 4.24L7 10.72l-3.92 1.67.38-4.24-2.8-3.21 4.15-.95L7 .33Z" stroke="#FAD656" fill="#FAD656" />
          </svg>
        </SfIcon>
      </slot>
      <!--@slot custom icon for negative (left) finalScore. Provide single icon that will be automatically repeated -->
      <slot
        v-for="index in parseInt(finalMax, 10) - parseInt(finalScore, 10)"
        name="icon-negative"
      >
        <SfIcon
          :key="`n${index}`"
          size="0.875rem"
          class="sf-rating__icon sf-rating__icon--negative"
          icon="star"
        >
          <svg
            width="14"
            height="13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M7 .33 9.19 4l4.15.95-2.8 3.21.38 4.24L7 10.72l-3.92 1.67.38-4.24-2.8-3.21 4.15-.95L7 .33Z" stroke="#FAD656" />
          </svg>
        </SfIcon>
      </slot>
    </div>
  </div>
</template>
<script>
import { SfIcon } from '@storefront-ui/vue';
export default {
  name: 'SfRating',
  components: {
    SfIcon
  },
  props: {
    /**
     * Maximum score
     */
    max: {
      type: Number,
      default: 5
    },
    /**
     * Score (obviously must be less than maximum)
     */
    score: {
      type: Number,
      default: 1
    },
    summaryRating: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    finalScore () {
      if (!this.score) {
        return 0;
      } else if (this.score < 0) {
        return 0;
      } else if (this.score > this.max && this.max > 0) {
        return this.max;
      } else if (this.max <= 0) {
        return 0;
      } else {
        return this.score;
      }
    },
    finalMax () {
      return !this.max || this.max <= 0 ? 1 : this.max;
    }
  }
};
</script>
<style lang="scss">
@import "~@storefront-ui/shared/styles/components/atoms/SfRating.scss";

.summary-rating {
  position: relative;

  &-items {
    display: flex;

    &__positive {
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
    }
  }
}

.rating-wrapper {
  display: flex;
}
</style>
