<template>
  <div class="o-my-account-bonus-card">
    <div class="o-my-account-bonus-card-header" v-if="isTablet || isDesktop">
      <div v-if="isDesktop" class="o-my-account-bonus-card-header__title">
        {{ $t('Bonus card') }}
      </div>
    </div>
    <div class="o-my-account-bonus-card-content">
      <div class="card-block">
        <MAccountBonusCard class="card-block__card" is-big-size is-big-card-with-discount />
        <div class="card-block__actions">
          <div class="action-add-card" @click="openModalAddCard">
            <span class="action-add-card__icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle opacity="0.1" cx="12" cy="12" r="12" fill="#EB6747" />
                <path d="M12 7V12M12 12H7M12 12V17M12 12H17" stroke="#EB6747" />
              </svg>
            </span>
            <span class="action-add-card__title">
              {{ $t("Add card") }}
            </span>
          </div>
        </div>
      </div>
      <MAccountBonusSummary />
    </div>
  </div>
</template>

<script>
import MAccountBonusCard from 'theme/components/molecules/m-account-bonus-card';
import MAccountBonusSummary from 'theme/components/molecules/m-account-bonus-summary';
import DeviceType from 'theme/mixins/DeviceType'
import { mapActions, mapGetters } from 'vuex';
import { ModalList } from 'theme/store/ui/modals';

export default {
  name: 'OMyAccountBonusCard',
  components: {
    MAccountBonusCard,
    MAccountBonusSummary
  },
  mixins: [
    DeviceType
  ],
  computed: {
    ...mapGetters({
      bonusCard: 'bonusCard/getBonusCard'
    })
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
      spawnNotification: 'notification/spawnNotification',
      hideBottomNavigation: 'ui/hideBottomNavigation'
    }),
    openModalAddCard () {
      this.$store.dispatch('ui/hideBottomNavigation', true)
      this.openModal({
        name: ModalList.BonusCardAdd
      })
    }
  }
};
</script>

<style lang="scss">
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";

.o-my-account-bonus-card {
  padding: var(--spacer-10);

  @include for-tablet {
    padding: 0;
  }

  @include for-desktop {
    padding: 0;
  }

  &-header {
    padding: 0;
    margin-bottom: var(--spacer-30);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &__title {
      font-family: var(--font-family-secondary);
      font-size: var(--font-size-24);
      font-weight: var(--font-medium);
      line-height: normal;
    }
  }

  &-content {
    background-color: var(--white);
    box-sizing: border-box;

    @media (max-width: $mobile-max) {
      width: px2rem(355);
    }

    @include for-tablet {
      padding: var(--spacer-30);
    }

    @include for-desktop {
      padding: var(--spacer-50);
    }

    .card-block {
      display: flex;
      min-height: 225px;

      @media (max-width: $mobile-max) {
        flex-direction: column;
        border-bottom: 2px solid var(--light-gray);
      }

      .card-block__actions {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 100%;

        @media (max-width: $mobile-max) {
          margin: var(--spacer-15) 0 var(--spacer-30);
        }

        @include for-tablet {
          flex-direction: column;
          justify-content: center;
        }

        @include for-desktop {
          margin: auto;
          height: px2rem(100);
        }

        .action-add-card {
          position: relative;
          cursor: pointer;

          @include for-tablet {
            flex-direction: column;
          }

          @include for-desktop {
            flex-direction: column;
            margin: 0 var(--spacer-20);
          }

          &__icon {
            position: absolute;
            left: 0;
            top: 2px;
          }

          &__title {
            margin-left: var(--spacer-35);
            color: var(--orange);
            font-size: var(--font-size-14);
            font-weight: var(--font-normal);
            white-space: nowrap;
          }

          &__gutter {
            width: var(--spacer-40);
            height: var(--spacer-30);
            border: 1px solid red;
          }
        }
      }
    }
  }
}
</style>
