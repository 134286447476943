<template>
  <div
    class="order-item__header"
    @click="$emit('click')"
  >
    <div class="header-order">
      <div class="header-order__image">
        <span :class="headerOrderImageClass" />
      </div>
      <div class="header-order__type">
        {{ orderTypeText }}
      </div>
      <div
        v-if="isOnlineOrder"
        class="header-order__number">
        № {{ order.number }}
      </div>
    </div>
    <div
      v-if="isOnlineOrder && order.statusInfo.group"
      class="order-status"
      :class="order.statusInfo.class"
    >
      <div class="order-status__text">
        {{ order.statusInfo.title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MOrderHeader',
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  computed: {
    isOnlineOrder () {
      return this.order.type === 'online';
    },
    headerOrderImageClass () {
      return this.isOnlineOrder ? 'header-order__image--online' : 'header-order__image--offline';
    },
    orderTypeText () {
      return this.$t(this.isOnlineOrder ? 'Online order' : 'Purchase in a store');
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/components/orders/orders";
@import '~theme/css/fonts';
@import "~theme/css/breakpoints";

.order-item__header {
  padding-top: var(--spacer-25);
  justify-content: space-between;
  align-items: start;
  cursor: pointer;

  @media (max-width: $mobile-max) {
    align-items: start;
  }

  .header-order {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    &__image {
      &--online {
        @include font-icon(var(--icon-cart));

        &:before {
          font-size: var(--font-size-24);
          color: #87A381;
        }
      }

      &--offline {
        @include font-icon(var(--icon-offline));

        &:before {
          font-size: var(--font-size-24);
          color: var(--orange);
        }
      }
    }

    &__type {
      margin-left: var(--spacer-10);
      font-size: var(--font-size-13);
      color: var(--orange)
    }

    &__number {
      margin-left: var(--spacer-10);
      font-size: var(--font-size-16);
      font-weight: var(--font-medium);
      color: var(--orange)
    }
  }

  .order-status {
    flex-shrink: 0;
    height: var(--spacer-24);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-size-11);
    line-height: var(--font-size-13);
    border-radius: var(--spacer-5);

    @include for-desktop {
      margin-right: var(--spacer-5);
    }

    &__text {
      padding: var(--spacer-5) var(--spacer-10);
    }
  }
}
</style>
