<template>
  <div class="order-item__address" @click="$emit('click')">
    <div v-if="isOnlineOrder">
      <span>{{ getDeliveryMethod(order.delivery_method) }}:</span>
      {{ order.delivery_address }}
    </div>
    <div v-else>
      {{ order.address }}
    </div>
  </div>
</template>

<script>
import { getDeliveryMethod } from 'theme/helpers/orders';

export default {
  name: 'MOrderAddress',
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  computed: {
    isOnlineOrder () {
      return this.order.type === 'online';
    }
  },
  methods: {
    getDeliveryMethod (deliveryMethod) {
      return getDeliveryMethod(deliveryMethod)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/breakpoints";

.order-item__address {
  padding-top: var(--spacer-8);
  cursor: pointer;
  color: var(--dark-gray);
  font-size: var(--font-size-14);
  line-height: var(--font-size-17);

  @media (max-width: $mobile-max) {
    padding-top: var(--spacer-12);
    font-size: var(--font-size-13);
    line-height: var(--font-size-16);
  }
}
</style>
